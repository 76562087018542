import CONSTANTS from "@/shared/fields";
const {DELIVERY_EXTRA_FILTER, formOptionsPreset} = CONSTANTS;
export const formOptionsGen = ($this) => {
  return [
    [
      {
        name: '프리셋', key: 'preset', options: [
          {
            text: '전체', click: () => {
              $this.form.list.goods_status = 'ALL';
              $this.form.list.stock_status = 'ALL';
              $this.form.list.display_status = 'ALL';
            }, variant: 'outline-dark'
          },
          {
            text: '등록상품', click: () => {
              $this.form.list.goods_status = 'registered';
              $this.form.list.stock_status = 'ALL';
              $this.form.list.display_status = 'ALL';
            }, variant: 'outline-info'
          },
          {
            text: 'Live', click: () => {
              $this.form.list.goods_status = 'registered';
              $this.form.list.stock_status = 'normal';
              $this.form.list.display_status = 'view';
            }, variant: 'outline-primary'
          },
          {
            text: '미노출', click: () => {
              $this.form.list.goods_status = 'ALL';
              $this.form.list.stock_status = 'ALL';
              $this.form.list.display_status = 'notview';
            }, variant: 'outline-warning'
          },
        ]
      },
      {
        name: '등록상태', key: 'goods_status', options: [
          {text: '전체', value: 'ALL'},
          {text: 'Processing', value: 'processing', variant: 'info'},
          {text: 'Registered', value: 'registered', variant: 'success'},
          {text: 'Terminated', value: 'terminated', variant: 'danger'}
        ]
      },
      {
        name: '재고상태', key: 'stock_status', options: [
          {text: '전체', value: 'ALL'},
          {text: '재고있음', value: 'normal', variant: 'success'},
          {text: '품절', value: 'runout', variant: 'warning'}
        ]
      },
      {
        name: '노출상태', key: 'display_status', options: [
          {text: '전체', value: 'ALL'},
          {text: '노출', value: 'view', variant: 'success'},
          {text: '미노출', value: 'notview', variant: 'warning'}
        ]
      },
      {
        name: '마스터(GM)', key: 'gm_id_exists', options: [
          {text: '전체', value: 'ALL'},
          {text: '매칭됨', value: 'y', variant: 'success'},
          {text: '매칭안됨', value: 'n', variant: 'warning'},
        ]
      },
      // {name: '마스터 매칭 상태', type: 'checkbox', key: 'gm_status', options: $this.$F.GM_STATUS}, // 너무 길어서 제외
      {
        name: '연동형태', key: 'manual', options: [
          {text: '전체', value: 'ALL'},
          {text: '자동(FEED)', value: 'auto', variant: 'info'},
          {text: '파트너관리', value: 'manual', variant: 'success'}
        ]
      },
      {
        name: '배송유형', key: 'delivery_type', options: [
          {text: '전체', value: 'ALL'},
          {text: '해외', value: '해외', variant: 'success'},
          {text: '국내', value: '국내', variant: 'primary'},
        ]
      },
      {name: '오늘도착', key: 'oneday_delivery', options: formOptionsPreset.AVAIL_Y},
      {name: '오늘출발', key: 'today_pick', options: formOptionsPreset.AVAIL_Y},
      {name: '주문제작', key: 'orderMade', options: formOptionsPreset.YESNO_Y},
      {name: '배송방식', key: 'deliveryExtra', options: DELIVERY_EXTRA_FILTER.map(e => Object.assign(e, {variant: 'dark'}))},
      {name: '매장픽업', key: 'store_pickup', options: formOptionsPreset.AVAIL_Y},
      {name: '선물포장', key: 'gift_packing', options: formOptionsPreset.AVAIL_Y},
      {name: '영문이름 여부', key: 'goods_nm_en', options: formOptionsPreset.EXISTS_YN},
      {
        name: '검색엔진', key: 'hide_es', options: [
          {text: '전체', value: 'ALL'},
          {text: '포함', value: 'n', variant: 'success'},
          {text: '제외', value: 'y', variant: 'warning'},
        ]
      },
      {name: '병행가격설정', key: 'hasPrRule', options: formOptionsPreset.EXISTS_Y},
      // {key: 'divider'},
      {
        name: '정렬기준', key: 'sortKey', options: [
          {text: '생성시각', value: '_object_id', variant: 'primary'},
          {text: '발란코드', value: 'goods_no', variant: 'primary'},
          {text: '등록시각', value: 'registered_dt', variant: 'info'},
          {text: '가격', value: 'price', variant: 'info'},
          {text: '소비자가', value: 'consumer', variant: 'info'},
          {text: '할인율', value: 'discount_rate', variant: 'info'},
          {text: '재고', value: 'tot_stock', variant: 'info'},
          {text: '시즌', value: 'season', variant: 'info'},
          {text: '옵션수', value: 'optionCount', variant: 'info'},
          {text: '재고있는옵션수', value: 'optionHasStockCount', variant: 'info'},
          {text: '오늘출발시각', value: 'today_pick_time', variant: 'info'},
        ]
      },
      {name: '정렬방향', key: 'sortDir', options: formOptionsPreset.SORT_DIR},
    ],
  ];
};

export const formFieldsGen = () => {
  return [
    {group: '상품속성'},
    {name: '발란코드', key: 'goods_no', type: 'number'},
    {name: 'Shop ID', key: 'shop_id', type: 'number', enableEnum: true, enableExists: true, enableStat: true, disableRange: true},
    {name: '상품명', key: 'goods_nm', type: 'string', width: 180},
    {name: 'Sku ID', key: 'sku_id', type: 'string', enableExists: true, enableStat: true, width: 150},
    {name: '통합 Sku', key: 'matched_sku_id', type: 'string', enableExists: true, enableStat: true, width: 150},
    {name: 'Short Sku', key: 'short_sku', type: 'string', enableExists: true, enableStat: true, width: 150},
    {name: '이미지 수', key: 'img_urls', type: 'number', enableStat: true},
    {name: '시즌', key: 'season', type: 'string', enableEnum: true, enableExists: true, enableStat: true, width: 135},
    {name: '원산지', key: 'origin', type: 'string', enableEnum: true, enableExists: true, enableStat: true, width: 135},
    {name: '컬러', key: 'color', type: 'string', enableExists: true, enableStat: true, disableRange: true, width: 120},
    {name: '매칭된 컬러', key: 'major_color', type: 'string', enableExists: true, enableStat: true, disableRange: true, width: 130},
    {name: '상품관리코드', key: 'mng_code', type: 'string', enableExists: true, width: 150},
    {name: '상품타입', key: 'goodsType', type: 'string', op: 'enum', enableEnum: true, enableExists: true, enableStat: true, width: 145},
    {name: '중고등급', key: 'usedGrade', type: 'string', op: 'enum', enableEnum: true, enableExists: true, enableStat: true, width: 145},
    {name: '주문제작', key: 'orderMade', type: 'boolean', enableExists: true, enableStat: true, width: 145},
    {name: '배송방식', key: 'deliveryExtra', type: 'array', enableEnum: true, width: 145},

    // {group: '상품상태'},

    {group: '재고, 가격, 옵션'},
    {
      name: '발란회원가', key: 'price', type: 'number', enableExists: true, rangePreset: [
        {text: '~ 1만원', gte: '', lte: 10000},
        {text: '1 ~ 3만원', gte: 10000, lte: 30000},
        {text: '3 ~ 10만원', gte: 30000, lte: 100000},
        {text: '10 ~ 20만원', gte: 100000, lte: 200000},
        {text: '20 ~ 30만원', gte: 200000, lte: 300000},
        {text: '30 ~ 50만원', gte: 300000, lte: 500000},
        {text: '50 ~ 100만원', gte: 500000, lte: 1000000},
        {text: '100 ~ 300만원', gte: 1000000, lte: 3000000},
        {text: '300 ~ 500만원', gte: 3000000, lte: 5000000},
        {text: '500 ~ 1000만원', gte: 5000000, lte: 10000000},
        {text: '1000만원 ~', gte: 10000000, lte: ''},
      ]
    },
    {name: '판매기준가', key: 'standardPrice', type: 'number', enableExists: true},
    {name: '소비자가', key: 'consumer', type: 'number', enableExists: true},
    {name: '원가', key: 'supply', type: 'number', enableExists: true, role: 'DEV'},
    {name: '할인율', key: 'discount_rate', type: 'number', enableStat: true, enableExists: true, op: 'range'},
    {name: '총 재고', key: 'tot_stock', type: 'number', op: 'range', enableStat: true, width: 100},
    {name: '옵션 재고', key: 'options.stock', type: 'number', op: 'range', enableStat: true, nested: true},
    {name: '옵션 Size', key: 'options.Size', type: 'string', op: 'like', enableStat: true, nested: true, width: 160},
    {name: '옵션 가격', key: 'options.goods_price', type: 'number', op: 'range', nested: true, enableExists: true, width: 130},
    {name: '옵션수', key: 'optionCount', type: 'number', op: 'range', enableStat: true},
    {name: '재고있는옵션수', key: 'optionHasStockCount', type: 'number', op: 'range', enableStat: true, width: 130},

    {group: 'GM 정보'},
    {name: 'GM 매칭상태', key: 'gm_status', type: 'string', enableEnum: true, enableExists: true, enableStat: true, width: 165},
    {name: 'B최저가 순위', key: 'b_rank', type: 'number', enableStat: true, width: 110},
    {name: 'B최저가 차이(%)', key: 'b_price_diff_per', type: 'number', op: 'range', enableStat: true, width: 130},
    {name: 'B Rank Class', key: 'b_rank_class', type: 'string', enableEnum: true, enableExists: true, enableStat: true, width: 160},
    {name: '발란추천가', key: 'gm_minPriceAll', type: 'number', op: 'range', enableExists: true, width: 120},
    {name: '발란추천가 차액', key: 'gm_minPriceAllGap', type: 'number', op: 'range', enableExists: true, width: 140},

    {group: 'Dynamic Pricing'},
    {name: 'DP Mode', key: 'dp_mode', type: 'string', op: 'enum', enableEnum: true, enableExists: true, enableStat: true, width: 145},
    {name: 'DP 할인율', key: 'dp_discountRate', type: 'number', op: 'range', enableExists: true, enableStat: true, width: 110},

    {group: '배송속성'},
    {name: '배송유형', key: 'delivery_type', type: 'string', op: 'enum', enableEnum: true, enableExists: true, enableStat: true, width: 145},
    {name: '오늘출발시각', key: 'today_pick_time', type: 'string', placeholder: 'ex) 13:00', enableEnum: true, enableStat: true, disableLike: true, width: 125},

    {group: '등록정보'},
    {name: '생성위치', key: '_cat', type: 'string', enableExists: true, enableStat: true, width: 150},
    {name: '생성 APP', key: '_capp', type: 'string', enableEnum: true, enableExists: true, enableStat: true, width: 150},
    {name: '생성시각', key: '_cdt', type: 'string', placeholder: 'ex) 2020-01-01 01:02:03', disableLike: true, width: 155},
    {name: '등록시각', key: 'registered_dt', type: 'string', placeholder: 'ex) 2020-01-01 01:02:03', disableLike: true, width: 155},

    {group: '미노출처리'},
    {name: '미노출시각', key: '_notview_dt', type: 'string', placeholder: 'ex) 2020-01-01 01:02:03', enableExists: true, disableLike: true, width: 155},
    {name: '미노출처리자', key: '_notview_by', type: 'string', enableEnum: true, enableExists: true, enableStat: true, disableLike: true, width: 145},
    {name: '노출상태 변경이유', key: 'display_status_reason', type: 'string', enableEnum: true, enableExists: true, enableStat: true, disableRange: true, width: 170},

    {group: '가격통계'},
    {name: '옵션 최고-최저가 차이', key: 'priceStat.priceDiff', type: 'number', enableStat: true, width: 160},
    {name: '옵션 최고-최저가 차이(%)', key: 'priceStat.priceDiffPer', type: 'number', enableStat: true, enableEnum: true, width: 185},
    {name: '옵션 평균-최저가 차이', key: 'priceStat.priceAvgDiff', type: 'number', enableStat: true, width: 160},
    {name: '옵션 평균-최저가 차이(%)', key: 'priceStat.priceAvgDiffPer', type: 'number', enableStat: true, enableEnum: true, width: 185},
    {name: '옵션 재고가중평균-최저가 차이', key: 'priceStat.priceAvgStockDiff', type: 'number', enableStat: true, width: 210},
    {name: '옵션 재고가중평균-최저가 차이(%)', key: 'priceStat.priceAvgStockDiffPer', type: 'number', enableStat: true, enableEnum: true, width: 230},
    {name: '옵션 가격 표준편차', key: 'priceStat.priceStd', type: 'number', enableStat: true, width: 140},
    {name: '옵션 가격 표준편차/옵션 최저가', key: 'priceStat.priceStdDiv', type: 'number', enableStat: true, width: 210},
  ];
};

export const formIncExcGen = () => {
  return [
    {name: '발란코드', key: 'goods_no', type: 'number'},
    {name: '파트너상품코드', key: 'goods_id', type: 'string'},
    {name: 'gid', key: 'gid', type: 'string'},
    {name: 'GM ID', key: 'gm_id', type: 'string'},
    {name: 'Shop ID', key: 'shop_id', type: 'number'},
    {name: 'Sku(파트너,통합)', key: 'sku', type: 'string', includePlaceholder: '포함할 SKU(파트너 및 통합 SKU 대상)', excludePlaceholder: '제외할 SKU'},
    {name: 'Sku ID', key: 'sku_id', type: 'string'},
    {name: '통합 Sku', key: 'matched_sku_id', type: 'string'},
    {name: 'Short Sku', key: 'short_sku', type: 'string'},
    {name: '브랜드 코드', key: 'brand_no', type: 'number'},
    {name: '최종카테고리 코드', key: 'category', type: 'string'},
    {name: '시즌', key: 'season', type: 'string'},
    {name: '원산지', key: 'origin', type: 'string'},
    {name: '컬러', key: 'color', type: 'string'},
    {name: '상품관리코드', key: 'mng_code', type: 'string'},
    {name: '오늘출발시각', key: 'today_pick_time', type: 'string'},
    {name: '미노출처리자', key: '_notview_by', type: 'string'},
    {name: '생성 APP', key: '_capp', type: 'string'},
  ];
}

export const groupFieldsGen = () => {
  return [
    {text: 'Shop ID', value: 'shop_id', type: 'number'},
    {text: '브랜드 코드', value: 'brand_no', type: 'number'},
    {text: '최종카테고리 코드', value: 'category', type: 'string'},
    {text: '시즌', value: 'season', type: 'string'},
    {text: '컬러', value: 'color', type: 'string'},
    {text: '매칭된 컬러', value: 'major_color', type: 'string'},
    {text: '원산지', value: 'origin', type: 'string'},
    {text: '배송유형', value: 'delivery_type', type: 'string'},
    {text: '상품타입', value: 'goodsType', type: 'string'},
    {text: '중고등급', value: 'usedGrade', type: 'string'},
    {text: '주문제작', value: 'orderMade', type: 'boolean'},
    {text: '배송방식', value: 'deliveryExtra', type: 'array'},

    {text: '총 재고', value: 'tot_stock', type: 'number'},
    {text: '할인율', value: 'discount_rate', type: 'number'},

    {text: '이미지 수', value: 'img_urls', type: 'number'},
    {text: '옵션수', value: 'optionCount', type: 'number'},
    {text: '재고있는옵션수', value: 'optionHasStockCount', type: 'number'},

    {text: '옵션 Size', value: 'options.Size', type: 'string', nested: true},
    {text: '옵션 재고', value: 'options.stock', type: 'number', nested: true},

    {text: 'Sku ID', value: 'sku_id', type: 'string'},
    {text: '통합 Sku', value: 'matched_sku_id', type: 'string'},
    {text: 'Short Sku', value: 'short_sku', type: 'string'},

    {text: 'GM ID', value: 'gm_id', type: 'string'},
    {text: '카탈로그 매칭상태', value: 'gm_status', type: 'string'},
    {text: 'B최저가 순위', value: 'b_rank', type: 'number'},
    {text: 'B최저가 차이(%)', value: 'b_price_diff_per', type: 'number'},
    {text: 'B Rank Class', value: 'b_rank_class', type: 'string'},
    {text: '발란추천가', value: 'gm_minPriceAll', type: 'number'},
    {text: '발란추천가 차액', value: 'gm_minPriceAllGap', type: 'number'},

    {text: 'DynamicPricing Mode', value: 'dp_mode', type: 'string'},
    {text: 'DynamicPricing 할인율', value: 'dp_discountRate', type: 'number'},
    {text: '병행가격설정 여부', value: 'hasPrRule', type: 'boolean'},

    {text: '연동형태', value: 'manual', type: 'boolean'},
    {text: '검색엔진제외', value: 'hide_es', type: 'boolean'},
    {text: '매장픽업가능', value: 'store_pickup', type: 'boolean'},
    {text: '선물포장가능', value: 'gift_packing', type: 'boolean'},
    {text: '오늘도착가능', value: 'oneday_delivery', type: 'string'},
    {text: '오늘출발가능', value: 'today_pick', type: 'boolean'},
    {text: '오늘출발시각', value: 'today_pick_time', type: 'string'},

    {text: '등록상태', value: 'goods_status', type: 'string'},
    {text: '노출상태', value: 'display_status', type: 'string'},
    {text: '노출상태 변경이유', value: 'display_status_reason', type: 'string'},
    {text: '미노출처리자', value: '_notview_by', type: 'string'},

    {text: '생성위치', value: '_cat', type: 'string'},
    {text: '생성 APP', value: '_capp', type: 'string'},

    {text: "옵션 최고-최저가 차이", value: "priceStat.priceDiff", type: "number"},
    {text: "옵션 최고-최저가 차이(%)", value: "priceStat.priceDiffPer", type: "number"},
    {text: "옵션 평균-최저가 차이", value: "priceStat.priceAvgDiff", type: "number"},
    {text: "옵션 평균-최저가 차이(%)", value: "priceStat.priceAvgDiffPer", type: "number"},
    {text: "옵션 재고가중평균-최저가 차이", value: "priceStat.priceAvgStockDiff", type: "number"},
    {text: "옵션 재고가중평균-최저가 차이(%)", value: "priceStat.priceAvgStockDiffPer", type: "number"},
    {text: "옵션 가격 표준편차", value: "priceStat.priceStd", type: "number"},
    {text: "옵션 가격 표준편차/옵션 최저가", value: "priceStat.priceStdDiv", type: "number"}
  ];
};
