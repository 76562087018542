<template>
  <div>
    <b-tabs class="mb-2" v-model="tabIndex">
      <b-tab title="DB 검색">
        <b-input-group class="mb-3">
          <b-input-group-prepend>
            <b-button variant="primary" @click="list()" :disabled="busy.list">
              <i class="fa fa-search"></i> 검색
              <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
            </b-button>
          </b-input-group-prepend>
          <b-form-input type="text" placeholder="goods_no, goods_id, sku_id를 넣어주세요" v-model="form.list.search" @keypress.enter.prevent.stop="list()" v-focus></b-form-input>
          <!--<b-input-group-append>
            <b-button variant="light" v-b-toggle.collapse1>검색조건 <i class="fa fa-chevron-down"></i></b-button>
          </b-input-group-append>-->
        </b-input-group>
        <template v-if="$R(['BALAANEER', 'E_GOODS_R']) || $S.user.group.includes('operator')">
          <shop-preset v-model="form.list.shop"></shop-preset>
          <div class="mb-2">
            <b-form-checkbox v-model="form.list.real_shop">특수 SHOP (0. 개인결제창, 204. CLEARANCE SALE, 2806. BALAAN REPAIR) 을 제외합니다</b-form-checkbox>
          </div>
        </template>

        <b-row class="mb-2">
          <b-col cols="6" lg="4">
            <div><small class="mb-n2">정렬순서</small></div>
            <b-form-radio-group class="col-form-label" v-model="form.list.orderby" :options="[
              {text: '정렬없음', value: 'no_order'},
              {text: '발란코드', value: 'goods_no'},
              {text: '높은가격', value: 'price_high'},
              {text: '낮은가격', value: 'price_low'},
            ]"></b-form-radio-group>
          </b-col>
          <b-col v-if="$R(['GOODS_R', 'E_GOODS_R'])" cols="6" lg="4">
            <div><small class="mb-n2">등록상태</small></div>
            <b-form-radio-group class="col-form-label" v-model="form.list.goods_status" :options="[
              {text: '전체', value: 'ALL'},
              {text: 'Processing', value: 'processing'},
              {text: 'Registered', value: 'registered'},
              {text: 'Terminated', value: 'terminated'}
            ]"></b-form-radio-group>
          </b-col>
          <b-col cols="6" lg="2">
            <div><small class="mb-n2">재고상태</small></div>
            <b-form-radio-group class="col-form-label" v-model="form.list.stock_status" :options="[
              {text: '전체', value: 'ALL'},
              {text: '재고있음', value: 'normal'},
              {text: '품절', value: 'runout'}
            ]"></b-form-radio-group>
          </b-col>
          <b-col v-if="$R(['GOODS_R', 'E_GOODS_R'])" cols="6" lg="2">
            <div><small class="mb-n2">노출상태</small></div>
            <b-form-radio-group class="col-form-label" v-model="form.list.display_status" :options="[
              {text: '전체', value: 'ALL'},
              {text: '노출', value: 'view'},
              {text: '미노출', value: 'notview'}
            ]"></b-form-radio-group>
          </b-col>
        </b-row>

        <b-collapse id="collapse1" v-model="collapse.detail">
          <b-row class="mb-2">
            <b-col cols="12" lg="3">
              <div><small class="mb-n2">연동형태</small></div>
              <b-form-radio-group class="col-form-label" v-model="form.list.manual" :options="[
                {text: '전체', value: 'ALL'},
                {text: '자동(FEED)', value: 'auto'},
                {text: '파트너관리', value: 'manual'}
              ]"></b-form-radio-group>
            </b-col>
            <b-col cols="12" lg="6">
              <div><small class="mb-n2">상품유형</small></div>
              <b-form inline>
                <b-form-radio-group class="col-form-label" v-model="form.list.goodsType" :options="[
                  {text: '전체', value: 'ALL'},
                  {text: '새상품만', value: 'new'},
                  {text: '빈티지만', value: 'used'}
                ]"></b-form-radio-group>
                <template v-if="form.list.goodsType === 'used'">
                  <b-button class="mr-1" size="sm" variant="light" @click="toggleUsedGrade()">전체</b-button>
                  <b-button class="mr-1" size="sm" variant="primary" @click="toggleUsedGrade('S')">S</b-button>
                  <b-button class="mr-1" size="sm" variant="success" @click="toggleUsedGrade('A')">A</b-button>
                  <b-button class="mr-2" size="sm" variant="warning" @click="toggleUsedGrade('B')">B</b-button>
                  <b-form-checkbox-group v-model="form.list.usedGrade">
                    <b-form-checkbox v-for="s in $C.USED_GRADE" :key="s.value" :value="s.value" :title="s.desc">{{s.text}}</b-form-checkbox>
                  </b-form-checkbox-group>
                </template>
              </b-form>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col cols="12" lg="6">
              <brand-preset class="mb-3" v-model="form.list.brand" :hideDisabled="true"></brand-preset>
            </b-col>
            <b-col cols="12" lg="6">
              <category-preset class="mb-3" v-model="form.list.category"></category-preset>
            </b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col lg="2">
              <small>가격조건</small><br/>
              <b-input-group>
                <b-form-input type="number" placeholder="최소가" v-model.number="form.list.min"></b-form-input>
                <b-input-group-append>
                  <b-button variant="primary"><i class="fa fa-exchange"></i></b-button>
                </b-input-group-append>
                <b-form-input type="number" placeholder="최대가" v-model.number="form.list.max"></b-form-input>
              </b-input-group>
            </b-col>
            <b-col md="2">
              <small>SKU ID 중간검색(다른 조건과 함께 사용해주세요)</small>
              <b-form-input type="text" v-model="form.list.sku_id"></b-form-input>
            </b-col>
            <b-col md="2">
              <small>총 재고합이 n 개 이상</small>
              <b-form-input type="text" placeholder="전체 재고가 이 숫자 이상입니다" v-model.number="form.list.tot_stock"></b-form-input>
            </b-col>
            <b-col md="2">
              <small>재고가 n 개 이상인 옵션이 존재</small>
              <b-form-input type="text" placeholder="options 의 재고가 n개 이상인 옵션이 존재합니다" v-model.number="form.list.opt_stock"></b-form-input>
            </b-col>
            <b-col md="2">
              <small>goods_no 범위선택</small><br/>
              <b-form inline>
                <b-form-input class="text-center w-100px" v-model.number="form.list.goods_no_from" placeholder="시작"></b-form-input>
                ~
                <b-form-input class="text-center w-100px" v-model.number="form.list.goods_no_to" placeholder="끝"></b-form-input>
              </b-form>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col md="3">
              <small>생성시각</small><br/>
              <b-form inline>
                <b-form-input class="text-center" v-model="form.list.created_from" placeholder="2020-04-01 00:00:00"></b-form-input>
                ~
                <b-form-input class="text-center" v-model="form.list.created_to" placeholder="2020-04-01 00:00:00"></b-form-input>
              </b-form>
            </b-col>
            <!--<b-col md="4">
              <small>송출시각</small><br/>
              <b-form inline>
                <b-form-input class="text-center" v-model="form.list.registered_from" placeholder="2020-04-01 00:00:00"></b-form-input>
                ~
                <b-form-input class="text-center" v-model="form.list.registered_to" placeholder="2020-04-01 00:00:00"></b-form-input>
              </b-form>
            </b-col>-->
            <b-col md="3">
              <small>최근수정시각</small><br/>
              <b-form inline>
                <b-form-input class="text-center" v-model="form.list.updated_from" placeholder="2020-04-01 00:00:00"></b-form-input>
                ~
                <b-form-input class="text-center" v-model="form.list.updated_to" placeholder="2020-04-01 00:00:00"></b-form-input>
              </b-form>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col md="3" sm="6">
              <small class="text-danger">오늘도착</small><br/>
              <b-form-radio-group class="col-form-label" v-model="form.list.oneday_delivery" :options="$C.OPTIONS.EXISTS_Y"></b-form-radio-group>
            </b-col>
            <b-col md="3" sm="6">
              <small class="text-success">오늘출발</small><br/>
              <b-form-radio-group class="col-form-label" v-model="form.list.today_pick" :options="$C.OPTIONS.EXISTS_Y"></b-form-radio-group>
            </b-col>
            <b-col md="3" sm="6">
              <small>주문제작</small><br/>
              <b-form-radio-group class="col-form-label" v-model="form.list.orderMade" :options="$C.OPTIONS.YESNO_Y"></b-form-radio-group>
            </b-col>
            <b-col md="3" sm="6">
              <small>배송유형</small><br/>
              <b-form-radio-group class="col-form-label" v-model="form.list.delivery_type" :options="options.delivery_type"></b-form-radio-group>
            </b-col>
            <b-col md="3" sm="6">
              <small>이미지체크</small><br/>
              <b-form-radio-group class="col-form-label" v-model="form.list.image_check" :options="options.image_check"></b-form-radio-group>
            </b-col>
            <b-col md="3" sm="6">
              <small>매장픽업</small><br/>
              <b-form-radio-group class="col-form-label" v-model="form.list.store_pickup" :options="options.AVAIL_Y"></b-form-radio-group>
            </b-col>
            <b-col md="3" sm="6">
              <small>선물포장</small><br/>
              <b-form-radio-group class="col-form-label" v-model="form.list.gift_packing" :options="options.AVAIL_Y"></b-form-radio-group>
            </b-col>
            <b-col md="3" sm="6">
              <small>검색엔진</small><br/>
              <b-form-radio-group class="col-form-label" v-model="form.list.hide_es" :options="options.hide_es"></b-form-radio-group>
            </b-col>
            <b-col md="3" sm="6">
              <small>배송방식</small><br/>
                <b-form-radio-group class="col-form-label" v-model="form.list.deliveryExtra" :options="$F.DELIVERY_EXTRA_FILTER"></b-form-radio-group>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col md="3">
              <small>옵션검색(발란몰 검색엔진 Live 상품 이용, 싱크가 안맞을 수 있습니다)</small><br/>
              <div class="col-form-label">
                <b-form-checkbox v-model="form.list.options">옵션검색</b-form-checkbox>
              </div>
            </b-col>
            <b-col md="3" v-if="form.list.options">
              <small>옵션 Size</small><br/>
              <b-form inline>
                <b-form-input class="w-100px mr-2" name="optionSize" placeholder="ex) XXL" v-model="form.list.optionSize"></b-form-input>
              </b-form>
            </b-col>
            <!--          <b-col md="3" v-if="form.list.options">
                        <small>옵션 재고</small><br/>
                        <b-form inline>
                          <b-form-input class="text-right w-65px" placeholder="%" v-model.number="form.list.optionStock"></b-form-input>
                          개 이상
                        </b-form>
                      </b-col>-->
          </b-row>

          <b-row class="mb-3">
            <b-col>
              <small>컬러</small><br/>
              <div class="clearfix">
                <b-button class="pull-left mr-2" size="sm" variant="primary" @click="e=>{ if (form.list.major_color.length === $C.COLORS.length) form.list.major_color = []; else form.list.major_color = $C.COLORS.map(e=>e.name); }">전체</b-button>
                <b-form-checkbox-group class="pull-left mt-1" name="group" v-model="form.list.major_color">
                  <b-form-checkbox v-for="c in $C.COLORS" :key="c.name" :value="c.name">
                    <div class="d-inline-block" :style="{backgroundColor:'#'+c.color, width:'22px', height:'22px'}" v-b-tooltip="c.name_ko"></div>
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </div>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col md="3">
              <small>goods_no</small>
              <b-form-textarea :rows="2" v-model="form.list.goods_no_include" placeholder="goods_no를 입력해주세요"></b-form-textarea>
            </b-col>
            <b-col md="3">
              <small>제외할 goods_no</small>
              <b-form-textarea :rows="2" v-model="form.list.goods_no_exclude" placeholder="제외할 goods_no를 입력해주세요"></b-form-textarea>
            </b-col>
            <b-col md="3">
              <small>goods_id</small>
              <b-form-textarea :rows="2" v-model="form.list.goods_id_include" placeholder="goods_id를 입력해주세요"></b-form-textarea>
            </b-col>
            <b-col md="3">
              <small>제외할 goods_id</small>
              <b-form-textarea :rows="2" v-model="form.list.goods_id_exclude" placeholder="제외할 goods_id를 입력해주세요"></b-form-textarea>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col md="3">
              <small>sku</small>
              <b-form-textarea :rows="2" v-model="form.list.sku_include" placeholder="SKU를 입력해주세요.
파트너 SKU 및 통합 SKU를 대상으로 검색합니다."></b-form-textarea>
            </b-col>
            <b-col md="3">
              <small>제외할 sku</small>
              <b-form-textarea :rows="2" v-model="form.list.sku_exclude" placeholder="제외할 SKU를 입력해주세요
파트너 SKU 및 통합 SKU를 대상으로 검색합니다."></b-form-textarea>
            </b-col>
            <b-col md="3">
              <small>gid</small>
              <b-form-textarea :rows="2" v-model="form.list.gid_include" placeholder="gid를 입력해주세요"></b-form-textarea>
            </b-col>
            <b-col md="3">
              <small>제외할 gid</small>
              <b-form-textarea :rows="2" v-model="form.list.gid_exclude" placeholder="제외할 gid를 입력해주세요"></b-form-textarea>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col lg="3">
              <small>한 번에 가져올 상품 수</small><br/>
              <b-form-input id="limit" type="text" placeholder="한 번에 가져올 상품 수" v-model.number="form.list.limit"></b-form-input>
            </b-col>
          </b-row>

          <b-form-checkbox v-if="$R('BALAANEER')" class="mb-3" v-model="form.list.minimal" inline>필수정보만 가져옵니다(Update 등을 빠르게 하기 위해)</b-form-checkbox>
          <b-form-checkbox v-if="$R('BALAANEER')" class="mb-3" v-model="form.list.godo" inline>발란몰 open 정보를 가져옵니다</b-form-checkbox>
        </b-collapse>

        <b-button class="mr-1" variant="primary" @click="list()" :disabled="busy.list">검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner></b-button>
        <b-button class="mr-1" variant="warning" @click="resetForm()">초기화</b-button>
        <b-button class="mr-1" variant="outline-success" v-b-toggle.collapse1>상세검색조건</b-button>

        <b-button v-if="$R('DEV')" class="ml-1 pull-right" :variant="query ? 'light' : 'outline-light'" @click="modal.query = true">Query</b-button>
        <router-link to="/terminated"><b-button class="pull-right" variant="outline-danger">종료상품조회</b-button></router-link>

        <b-modal title="추가 Query 지정" size="lg" v-model="modal.query" cancel-title="Clear" cancel-variant="light" @cancel="query = ''">
          <h6>goods_no: 123, name: 'aaa'</h6>
          형식으로 입력해주세요. {} 를 제외한 object 형식에 문제가 없다면 쿼리에 추가됩니다.
          <b-textarea v-model="query" rows="10"></b-textarea>
        </b-modal>
      </b-tab>

      <b-tab>
        <template v-slot:title>
          검색엔진
          <b-badge class="ml-1" variant="success">BETA</b-badge>
        </template>

        <b-input-group class="mb-1">
          <b-input-group-prepend>
            <b-button variant="primary" @click="list()" :disabled="busy.list">
              <i class="fa fa-search"></i> 검색
              <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
            </b-button>
          </b-input-group-prepend>
          <b-form-input type="text" placeholder="발란코드, 파트너상품코드, 상품명, SKU, 통합 SKU, 시즌, 원산지, 상품관리코드, 상세설명" v-model="form.list.search" @keypress.enter="list()" v-focus></b-form-input>
          <b-input-group-append>
            <b-button variant="light" @click="getEsQuery()">쿼리확인</b-button>
          </b-input-group-append>
        </b-input-group>

        <form-options v-model="form.list" v-bind="{formOptions}">
          <template v-slot:slotEnd>
            <div class="flex-grow-0 mb-1 mr-2">
              <small>Color 선택</small><br/>
              <color-checkbox v-model="form.list.major_color"></color-checkbox>
            </div>
            <div class="flex-grow-0 mb-1 mr-2">
              <small>기본 상품수</small><br/>
              <b-form-input class="text-center w-70px" size="sm" title="한 번에 가져올 상품 수" v-model.number="form.list.limit" @keypress.enter="list()"></b-form-input>
            </div>
          </template>
        </form-options>

        <b-collapse id="collapseES" v-model="collapse.esDetail">
          <hr/>
          <b-row>
            <b-col v-if="$R(['BALAANEER', 'E_GOODS_R']) || $S.user.group.includes('operator')" class="mb-1" cols="12" md="12" xl="4">
              <shop-preset v-model="form.list.shop"></shop-preset>
            </b-col>
            <b-col class="mb-1" cols="12" md="6" xl="4">
              <brand-preset class="" v-model="form.list.brand" :hideDisabled="true"></brand-preset>
            </b-col>
            <b-col class="mb-1" cols="12" md="6" xl="4">
              <category-preset class="" v-model="form.list.category"></category-preset>
            </b-col>
          </b-row>
          <div class="mt-n2">
            <small class="pointer text-warning" v-b-toggle.collapseExc>제외할 Shop, Brand, Category 설정</small>
            <div class="py-1">
              <b-collapse id="collapseExc" class="b-a-1 m-n1 p-2 border-warning">
                <b-row>
                  <b-col v-if="$R(['BALAANEER', 'E_GOODS_R']) || $S.user.group.includes('operator')" class="mb-1" cols="12" md="12" xl="4">
                    <shop-preset v-model="form.list.shop_exclude"></shop-preset>
                  </b-col>
                  <b-col class="mb-1" cols="12" md="6" xl="4">
                    <brand-preset class="" v-model="form.list.brand_exclude" :hideDisabled="true"></brand-preset>
                  </b-col>
                  <b-col class="mb-1" cols="12" md="6" xl="4">
                    <category-preset class="" v-model="form.list.category_exclude"></category-preset>
                  </b-col>
                </b-row>
              </b-collapse>
            </div>
          </div>

          <div class="fs-12 bold">
            검색 필드
          </div>
          <form-fields ref="fields" v-model="form.list.fields" :name="$options.name" :customFormFields.sync="customFormFields"
                       v-bind="{formFields, defaultFields, statUri: '/goods/confirmed/fieldStat'}" @enter="list()"></form-fields>
          <form-inc-exc ref="incExc" v-model="form.list.incExc" :name="$options.name" :customFormIncExc.sync="customFormIncExc"
                       v-bind="{formIncExc, defaultIncExc}"></form-inc-exc>

          <div class="mb-2">
            <b-form-checkbox v-if="$R('BALAANEER')" v-model="form.list.minimal" inline>필수정보만 가져옵니다(Update 등을 빠르게 하기 위해)</b-form-checkbox>
            <b-form-checkbox v-if="$R('BALAANEER')" v-model="form.list.godo" inline>발란몰 open 정보를 가져옵니다</b-form-checkbox>
          </div>
        </b-collapse>

        <div class="mt-2 clearfix">
          <div class="pull-left">
            <b-button class="mr-1" variant="primary" @click="list()" :disabled="busy.list">검색<b-spinner class="ml-1" small v-if="busy.list"></b-spinner></b-button>
            <b-button class="mr-1" variant="warning" @click="resetForm()">초기화</b-button>
            <b-button class="mr-1" variant="outline-primary" @click="modal.downIds = true">검색결과 ID Down</b-button>
            <b-button class="mr-1" variant="outline-success" v-b-toggle.collapseES>상세검색조건</b-button>
          </div>
          <div class="pull-right">
            <form-preset v-model="form.list" v-bind="{page: $options.name, defaultForm: defaultForm.list}" @resetForm="resetForm()"></form-preset>
          </div>
        </div>
      </b-tab>

      <b-tab>
        <template v-slot:title>
          통계
          <b-badge class="ml-1" variant="success">BETA</b-badge>
        </template>

        <b-row>
          <b-col cols="12" xl="9">
            <b-input-group class="mb-1">
              <b-input-group-prepend>
                <b-button variant="success" @click="stat()" :disabled="busy.stat">
                  <i class="fa fa-pie-chart"></i> 통계
                  <b-spinner class="ml-1" small v-if="busy.stat"></b-spinner>
                </b-button>
              </b-input-group-prepend>
              <b-form-input type="text" placeholder="발란코드, 파트너상품코드, 상품명, SKU, 통합 SKU, 시즌, 원산지, 상품관리코드, 상세설명" v-model="form.list.search" @keypress.enter="stat()"></b-form-input>
              <b-input-group-append>
                <b-button variant="light" @click="getEsQuery()">쿼리확인</b-button>
              </b-input-group-append>
            </b-input-group>

            <form-options v-model="form.list" v-bind="{formOptions, excludeKeys: ['sortKey', 'sortDir']}">
              <template v-slot:slotEnd>
                <div class="flex-grow-0 mb-1 mr-2">
                  <small>Color 선택</small><br/>
                  <color-checkbox v-model="form.list.major_color"></color-checkbox>
                </div>
              </template>
            </form-options>

            <b-collapse id="collapseStat" v-model="collapse.statDetail">
              <hr/>
              <b-row>
                <b-col v-if="$R(['BALAANEER', 'E_GOODS_R']) || $S.user.group.includes('operator')" class="mb-1" cols="12" md="12" xl="4">
                  <shop-preset v-model="form.list.shop"></shop-preset>
                </b-col>
                <b-col class="mb-1" cols="12" md="6" xl="4">
                  <brand-preset class="" v-model="form.list.brand" :hideDisabled="true"></brand-preset>
                </b-col>
                <b-col class="mb-1" cols="12" md="6" xl="4">
                  <category-preset class="" v-model="form.list.category"></category-preset>
                </b-col>
              </b-row>
              <div class="mt-n2">
                <small class="pointer text-warning" v-b-toggle.collapseExc>제외할 Shop, Brand, Category 설정</small>
                <div class="py-1">
                  <b-collapse id="collapseExc" class="b-a-1 m-n1 p-2 border-warning">
                    <b-row>
                      <b-col v-if="$R(['BALAANEER', 'E_GOODS_R']) || $S.user.group.includes('operator')" class="mb-1" cols="12" md="12" xl="4">
                        <shop-preset v-model="form.list.shop_exclude"></shop-preset>
                      </b-col>
                      <b-col class="mb-1" cols="12" md="6" xl="4">
                        <brand-preset class="" v-model="form.list.brand_exclude" :hideDisabled="true"></brand-preset>
                      </b-col>
                      <b-col class="mb-1" cols="12" md="6" xl="4">
                        <category-preset class="" v-model="form.list.category_exclude"></category-preset>
                      </b-col>
                    </b-row>
                  </b-collapse>
                </div>
              </div>

              <div class="fs-12 bold">
                검색 필드
              </div>
              <form-fields ref="fields" v-model="form.list.fields" :name="$options.name" :customFormFields.sync="customFormFields"
                           v-bind="{formFields, defaultFields, statUri: '/goods/confirmed/fieldStat'}" @enter="list()"></form-fields>
              <form-inc-exc ref="incExc" v-model="form.list.incExc" :name="$options.name" :customFormIncExc.sync="customFormIncExc"
                            v-bind="{formIncExc, defaultIncExc}"></form-inc-exc>

              <div class="mb-2">
                <b-form-checkbox v-if="$R('BALAANEER')" v-model="form.list.minimal" inline>필수정보만 가져옵니다(Update 등을 빠르게 하기 위해)</b-form-checkbox>
                <b-form-checkbox v-if="$R('BALAANEER')" v-model="form.list.godo" inline>발란몰 open 정보를 가져옵니다</b-form-checkbox>
              </div>
            </b-collapse>
          </b-col>
          <b-col class="border-left" cols="12" xl="3">
            <div class="fs-12 bold">
              Group By
            </div>
            <v-select v-model="form.aggs.groupFields" :options="groupFields" index="value" multiple push-tags placeholder="컬럼 선택"
                      @input="groupFieldsChange()"></v-select>
            <template v-for="(f, idx) in form.aggs.groupFields">
              <div class="mt-2" v-if="form.aggs.groupFieldSetting[f]" :key="idx">
                <div class="fs-12 bold">
                  {{groupFields.find(e => e.value === f).label}} Group Size
                  <i class="fa fa-question-circle ml-1" v-b-tooltip="'갯수가 많은 순으로 Group Size 만큼 가져오고 나머지는 (OTHER_COUNT) 에 포함됩니다.'"></i>
                </div>
                <b-form-input type="number" class="text-center w-100px" v-model.number="form.aggs.groupFieldSetting[f].size" placeholder="group size"></b-form-input>
                <b-form inline>
                  <b-form-checkbox class="mr-2" v-model="form.aggs.groupFieldSetting[f].avgOn">AVG</b-form-checkbox>
                  <b-form-select class="fs-12" v-if="form.aggs.groupFieldSetting[f].avgOn" v-model="form.aggs.groupFieldSetting[f].avg"
                                 :options="formFields.filter(e => e.type === 'number').map(e => ({text: `${e.name}(${e.key})`, value: e.key}))"></b-form-select>
                </b-form>
                <b-form inline>
                  <b-form-checkbox class="mr-2" v-model="form.aggs.groupFieldSetting[f].minOn">MIN</b-form-checkbox>
                  <b-form-select class="fs-12" v-if="form.aggs.groupFieldSetting[f].minOn" v-model="form.aggs.groupFieldSetting[f].min"
                                 :options="formFields.filter(e => e.type === 'number').map(e => ({text: `${e.name}(${e.key})`, value: e.key}))"></b-form-select>
                </b-form>
                <b-form inline>
                  <b-form-checkbox class="mr-2" v-model="form.aggs.groupFieldSetting[f].maxOn">MAX</b-form-checkbox>
                  <b-form-select class="fs-12" v-if="form.aggs.groupFieldSetting[f].maxOn" v-model="form.aggs.groupFieldSetting[f].max"
                                 :options="formFields.filter(e => e.type === 'number').map(e => ({text: `${e.name}(${e.key})`, value: e.key}))"></b-form-select>
                </b-form>
                <b-form inline>
                  <b-form-checkbox class="mr-2" v-model="form.aggs.groupFieldSetting[f].sumOn">SUM</b-form-checkbox>
                  <b-form-select class="fs-12" v-if="form.aggs.groupFieldSetting[f].sumOn" v-model="form.aggs.groupFieldSetting[f].sum"
                                 :options="formFields.filter(e => e.type === 'number').map(e => ({text: `${e.name}(${e.key})`, value: e.key}))"></b-form-select>
                </b-form>
              </div>
            </template>
          </b-col>
        </b-row>

        <div class="mt-2 clearfix">
          <div class="pull-left">
            <b-button class="mr-1" variant="success" @click="stat()" :disabled="busy.stat">통계<b-spinner class="ml-1" small v-if="busy.stat"></b-spinner></b-button>
            <b-button class="mr-1" variant="warning" @click="resetStatForm()">초기화</b-button>
            <b-button class="mr-1" variant="outline-success" v-b-toggle.collapseStat>상세검색조건</b-button>
          </div>
          <div class="pull-right">
            <form-preset v-model="form.list" v-bind="{page: $options.name, defaultForm: defaultForm.list}" @resetForm="resetForm()"></form-preset>
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <confirmed-modal ref="confirmedModal" v-bind="{modal, momentBiz, shopMap, targetMap}"></confirmed-modal>

    <b-modal title="노출상태 일괄변경" size="lg" v-model="modal.displayStatus" @ok="setDisplayStatus">
      <b-alert show variant="info">총 {{items.list.filter(e=>e.selected).length}} 건의 상품의 상태를 변경합니다</b-alert>
      <label>노출상태(display_status)</label>
      <b-form-radio-group class="col-form-label" v-model="displayStatus" :options="[
        {text: '노출', value: 'view'},
        {text: '미노출', value: 'notview'}
      ]"></b-form-radio-group>
      <br/>
      <label>즉시반영</label>
      <b-form-radio-group class="col-form-label" v-model="displayStatusRelay" :options="[
        {text: '반영', value: true},
        {text: '미반영', value: false}
      ]"></b-form-radio-group>
      <small class="text-muted">즉시반영을 선택하시면 바로 외부몰에 전파됩니다.</small>
      <br/>
      <label>변경사유</label>
      <b-input v-model="displayStatusReason"></b-input>
    </b-modal>

    <b-modal title="상품 일괄종료" size="lg" v-model="modal.terminate" :no-close-on-backdrop="busy.terminate" :no-close-on-esc="busy.terminate" hide-header-close>
      <b-alert show variant="info">총 {{items.list.filter(e=>e.selected).length}} 건의 상품을 종료처리 합니다.</b-alert>
      <b-alert show variant="danger">종료된 상품은 복구가 불가능합니다. 꼭 최종 확인 후 종료를 진행해주세요.</b-alert>

      <label>상품 종료사유</label>
      <b-input v-model="terminateReason"></b-input>

      <template v-slot:modal-footer="{ cancel }">
        <b-button class="m-1" variant="danger" @click="terminate()" :disabled="busy.terminate">
          상품 종료
          <b-spinner class="ml-1" small v-if="busy.terminate"></b-spinner>
        </b-button>
        <b-button v-if="$R('ADMIN')" class="m-1" variant="outline-danger" @click="terminate(true)" :disabled="busy.terminate">
          상품 완전종료
          <b-spinner class="ml-1" small v-if="busy.terminate"></b-spinner>
        </b-button>
        <b-button class="m-1" variant="secondary" @click="cancel()" :disabled="busy.terminate">
          닫기
          <b-spinner class="ml-1" small v-if="busy.terminate"></b-spinner>
        </b-button>
      </template>
    </b-modal>
<!--    <modal-base :diffListModal="modal.diffList" :diff="diff" :type="'diff'"></modal-base>-->
<!--    <b-modal title="수정이력 확인" size="lg" v-model="modal.diffList" ok-title="닫기" ok-only>-->
<!--      <div v-if="diff">-->
<!--        <template v-for="d in diff.slice().reverse()">-->
<!--          <div class="clearfix">-->
<!--            <div class="pull-right">-->
<!--              <b-badge class="mr-1" variant="success">{{d._at}}</b-badge>-->
<!--              <b-badge class="" variant="light">{{d._dt}}</b-badge>-->
<!--              &lt;!&ndash; 관리자이거나 본인이면 rollback 버튼 &ndash;&gt;-->
<!--              <b-button size="sm" class="rollback" @click="rollbackDiff(d, item)">되돌리기</b-button>-->
<!--            </div>-->
<!--            <div><b-badge variant="primary">{{d._name}}</b-badge></div>-->
<!--            <span v-html="formatDiff(d)"></span>-->
<!--          </div>-->
<!--          <hr/>-->
<!--        </template>-->
<!--      </div>-->
<!--      <div v-else class="text-center">-->
<!--        수정이력이 없습니다-->
<!--      </div>-->
<!--    </b-modal>-->

    <b-modal title="이미지 재처리" size="lg" v-model="modal.image" @ok="imageProcessing()">
      <b-alert show variant="info">총 {{items.list.filter(e=>e.selected).length}} 건의 상품의 이미지를 재처리합니다</b-alert>
      <ul>
        <li>기본 룰 : 크롬 확장프로그램 이미지 치환 -> 최초 상품 등록시의 원본 이미지 -> 현재의 원본 이미지 순으로 체크하여 먼저 발견된 이미지로 처리됩니다.</li>
        <li>최초 상품 등록시의 원본 이미지와 현재의 원본 이미지가 달라서 바뀐 이미지로 처리하려면 아래에서 선택해주세요</li>
        <li>원본 이미지의 순서와 현재 이미지의 순서가 다르다면 이미지 재처리 후 다시 바꿔줘야 합니다</li>
        <li>긴 이미지(SHOP 설정 참고)가 발견될 때, 상세설명의 이미지도 교체됩니다</li>
      </ul>
      <b-form-radio-group v-model="imageIntent" :options="[
        {text: '기본 룰', value: 'default'},
        {text: '크롬 이미지 치환 혹은 네이버용 백그라운드 처리 이미지 결과를 무시하고 최초 등록시의 원본 이미지로 재처리', value: 'useOriginal'},
        {text: '크롬 이미지 치환 혹은 네이버용 백그라운드 처리 이미지 결과를 무시하고 현재의 원본(mapped) 이미지로 재처리', value: 'useMapped'},
      ]" stacked>
      </b-form-radio-group>
    </b-modal>

    <b-modal title="발란몰로 싱크" size="lg" v-model="modal.godo" @ok="syncGodo()">
      <b-alert show variant="info">총 {{items.list.filter(e=>e.selected).length}} 건의 상품을 발란몰로 싱크합니다</b-alert>
      <b-form-checkbox v-model="syncForce">특수샵(204) 싱크</b-form-checkbox>
      <span>싱크할 필드 선택</span>
      <b-button class="ml-1" size="sm" variant="primary" @click="selectedGodoCols = godoCols.length === selectedGodoCols.length ? [] : godoCols.map(e=>e.value)">전체선택/해제</b-button>
      <br/>
      <b-form-checkbox v-for="c in godoCols" v-model="selectedGodoCols" :key="c.value" :value="c.value">{{c.text}} ({{c.value}}) : {{c.desc}}</b-form-checkbox>
    </b-modal>

    <b-modal title="ES Query 확인" v-model="modal.esQuery">
      <b-textarea v-model="esQuery" rows="50"></b-textarea>
      <template v-slot:modal-footer="{ ok }">
        <b-button v-if="tabIndex === 1" variant="success" @click="runCustomQuery">
          이 쿼리로 실행
        </b-button>
        <b-button variant="primary" @click="ok">
          확인
        </b-button>
      </template>
    </b-modal>

    <b-modal title="전체 검색결과 ID 다운로드" size="lg" v-model="modal.downIds" :no-close-on-backdrop="busy.downIds" :no-close-on-esc="busy.downIds" hide-header-close>
      <b-alert show variant="info">ID 필드를 선택하여 전체 검색결과에 대해 다운로드 합니다.</b-alert>
      <b-alert show variant="warning">다운로드는 200 만개 까지 가능하며, 그 이상은 조건을 조절하여 순차 다운르드 해 주세요. 다운로드에는 10 만개 기준 20 여초 소요됩니다.</b-alert>
      <b-alert show variant="warning">다운로드 중에는 모달을 닫을 수 없습니다. 먼저 검색을 통해 전체 수를 확인 후 다운을 진행해주세요.</b-alert>
      <span>ID Field</span>
      <b-form-radio-group class="col-form-label" v-model="returnIdField" :disabled="busy.downIds" :options="[
        {text: '발란코드(goods_no)', value: 'goods_no'},
        {text: '파트너상품코드(goods_id)', value: 'goods_id'},
        {text: 'GID', value: 'gid'},
        {text: 'GM ID', value: 'gm_id'},
        {text: 'ObjectId', value: '_object_id'}
      ]"></b-form-radio-group>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="success" @click="downIds()" :disabled="busy.downIds">
          다운로드
          <b-spinner class="ml-1" small v-if="busy.downIds"></b-spinner>
        </b-button>
        <b-button variant="secondary" @click="cancel()" :disabled="busy.downIds">
          닫기
          <b-spinner class="ml-1" small v-if="busy.downIds"></b-spinner>
        </b-button>
      </template>
    </b-modal>

    <iframe name="xlsx_frame" style="display:none"></iframe>
    <form :action="getHost() + '/data/down'" ref="xlsx_form" method="POST" target="xlsx_frame" style="width:1px;height:1px;visibility:hidden">
      <input ref="json_data" type="hidden" name="j" />
    </form>


    <template v-if="tabIndex === 0 || tabIndex === 1">
      <div class="clearfix">
        <div v-if="$R('GOODS_X')" class="pull-left">
          <b-button class="m-1" variant="info" @click="setDisplayStatusModal">노출상태일괄변경</b-button>
          <b-button class="m-1" variant="primary" @click="imageProcessingModal">이미지 재처리</b-button>
          <b-button class="m-1" variant="success" @click="syncGodoModal">발란몰로 싱크</b-button>
          <b-button class="m-1" variant="success" @click="syncStockMulti" :disabled="busy.syncStockMulti">재고 일괄갱신
            <b-spinner class="ml-1" small v-if="busy.syncStockMulti"></b-spinner>
          </b-button>
<!--          <b-button class="m-1" variant="warning" @click="removeSoldoutOption" title="품절처리로 인해 재고가 0으로 고정된 옵션의 기록을 제거하여 재고관리가 가능하게 합니다"-->
<!--                    :disabled="busy.removeSoldoutOption">-->
<!--            품절옵션 원복-->
<!--            <b-spinner class="ml-1" small v-if="busy.removeSoldoutOption"></b-spinner>-->
<!--          </b-button>-->
          <b-button class="m-1" variant="danger" @click="terminateModal()" :disabled="busy.terminate">
            상품 종료
            <b-spinner class="ml-1" small v-if="busy.terminate"></b-spinner>
          </b-button>
        </div>
        <div class="pull-right">
          <b-dropdown right variant="light" class="m-1">
            <template #button-content>
              <i class="fa fa-copy"></i>
            </template>
            <b-dropdown-item @click="copy('goods_no')">발란코드</b-dropdown-item>
            <b-dropdown-item @click="copy('goods_no', {separator: 'tab'})">발란코드(탭)</b-dropdown-item>
            <b-dropdown-item @click="copy('goods_no', {separator: 'comma'})">발란코드(콤마)</b-dropdown-item>
            <b-dropdown-item @click="copy('goods_no', {separator: 'quotes'})">발란코드(따옴표)</b-dropdown-item>
            <b-dropdown-item @click="copy('goods_id')">Goods ID</b-dropdown-item>
            <b-dropdown-item @click="copy('goods_id', {separator: 'tab'})">Goods ID(탭)</b-dropdown-item>
            <b-dropdown-item @click="copy('goods_id', {separator: 'quotes'})">Goods ID(따옴표)</b-dropdown-item>
          </b-dropdown>
          <b-dropdown right text="XLSX" variant="success" class="m-1">
            <b-dropdown-item @click="preDown('xlsx')">기본</b-dropdown-item>
            <b-dropdown-item @click="downAD('xlsx')">쇼핑검색광고</b-dropdown-item>
            <b-dropdown-item @click="preDown('xlsx', {template: 'tf'})">재고 TF</b-dropdown-item>
<!--            <b-dropdown-item @click="downOptions('xlsx')">옵션별</b-dropdown-item>-->
          </b-dropdown>

          <b-button-group class="m-1">
            <b-button :variant="itemMode === 'list' ? 'dark' : 'light'" @click="itemMode = 'list'"><i class="fa fa-list"></i></b-button>
            <b-button :variant="itemMode === 'pic' ? 'dark' : 'light'" @click="itemMode = 'pic'"><i class="fa fa-picture-o"></i></b-button>
          </b-button-group>
        </div>
      </div>

      <c-table ref="c-table:list" v-if="itemMode === 'list'" :table-data="items.list" :fields="fields" :perPage.sync="perPage"
               :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list"
               :caption="`현재 ${items.list.length}${hasMore.list && !total.list ? '+' : ''} 개` +
                `${total.list ? ` (총 ${total.list.value.comma()}${total.list.relation === 'gte' ? '+' : ''} 개)` : ''}`"
               :emptyHtml="'해당 조건에 맞는 상품이 없습니다. 검색조건을 확인해주세요'"
               @btn-clicked="btnAction" @get-more="list(true)">
      </c-table>

      <div v-else class="bg-white rounded p-2">
        <div class="clearfix">
          <div class="pull-right">
            <b-button variant="primary" @click="selectAll(true)"> 전체선택</b-button>
            <b-button variant="warning" @click="selectAll(false)"> 선택해제</b-button>
          </div>
          <b-form inline>
            <b-button-group>
              <b-button :variant="picGroup === 'ALL' ? 'primary' : 'light'" @click="picGroup = 'ALL'">
                {{items.list.length}} 개 전체 아이템
              </b-button>
              <b-button :variant="picGroup === 'selected' ? 'success' : 'light'" @click="picGroup = 'selected'">
                {{items.list.filter(e=>e.selected).length}} 개 선택된 아이템
              </b-button>
            </b-button-group>
            <b-input-group class="ml-1">
              <b-input-group-prepend>
                <b-input-group-text>
                  <i class="fa fa-filter"></i>
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input v-model="picFilter"></b-form-input>
              <b-input-group-append v-if="picFilter">
                <b-input-group-text>
                  {{picFilteredCnt}} 개
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <b-input-group class="ml-1">
              <b-input-group-prepend><b-input-group-text>이미지 너비</b-input-group-text></b-input-group-prepend>
              <b-form-input class="w-65px text-center" type="number" v-model.number="picWidth"></b-form-input>
              <b-input-group-append><b-input-group-text>px</b-input-group-text></b-input-group-append>
              <b-input-group-append><b-button @click="picWidth=175">175</b-button></b-input-group-append>
              <b-input-group-append><b-button @click="picWidth=195">195</b-button></b-input-group-append>
              <b-input-group-append><b-button @click="picWidth=225">225</b-button></b-input-group-append>
              <b-input-group-append><b-button @click="picWidth=265">265</b-button></b-input-group-append>
              <b-input-group-append><b-button @click="picWidth=318">318</b-button></b-input-group-append>
              <b-input-group-append><b-button @click="picWidth=400">400</b-button></b-input-group-append>
            </b-input-group>
            <b-checkbox class="ml-2" v-model="picInfoTop">상단정보</b-checkbox>
            <b-checkbox class="ml-2" v-model="picInfoBottom">하단정보</b-checkbox>
          </b-form>
        </div>
        <div class="flex-row flex-wrap d-flex">
          <div v-for="e of items.list" v-if="(picFilter ? e.filtered : true) && (picGroup === 'ALL' || e.selected)"
               class="flex-grow-0 m-1 position-relative" :style="{width:picWidth+'px', padding:'3px', border:e.selected ? '4px solid #20a8d8' : '1px solid #eee'}">
            <!--<b-checkbox v-model="e.selected" class="position-absolute"></b-checkbox>-->
            <!-- 상단 정보 -->
            <div class="position-absolute text-right" style="right:0;line-height:15px">
              <!--<b-badge variant="success">{{e.shop_id}}. {{e.shop}}</b-badge><br/>-->
              <a :href="`/#/goods/${e.goods_no}`" target="_blank" class="badge badge-primary">{{e.goods_no}} <i class="fa fa-external-link"></i></a>
              <b-badge class="ml-1 pointer" size="sm" @click="showModal({item: e})">상세</b-badge>
              <div v-if="picInfoTop">
                <b-badge variant="warning">{{e.brand_nm}}</b-badge><br/>
                <template v-if="e.consumer !== e.price">
                  <b-badge variant="dark"><del>{{$utils.comma(e.consumer)}}</del> → {{$utils.comma(e.price)}} 원</b-badge>
                </template>
                <template v-else>
                  <b-badge variant="dark">{{$utils.comma(e.price)}} 원</b-badge>
                </template>
              </div>
            </div>
            <img :src="e.img_urls[0]" class="w-100" @click="picClick(e)"/>
            <!-- 하단 정보 -->
            <div v-if="picInfoBottom" class="info-bottom" style="line-height:18px">
              <b-badge variant="success">{{e.shop_id}}. {{e.shop}}</b-badge><br/>
              <template v-if="picWidth < 250">
                <div class="text-truncate overflow-hidden fs-11 bold" :title="e.goods_nm">{{e.goods_nm}}</div>
                <div class="text-truncate overflow-hidden fs-11" :title="e.org_name">{{e.org_name}}</div>
              </template>
              <template v-else>
                <div class="text-truncate overflow-hidden bold" :title="e.goods_nm">{{e.goods_nm}}</div>
                <div class="text-truncate overflow-hidden" :title="e.org_name">{{e.org_name}}</div>
              </template>
              <div class="clearfix mb-1">
                <div v-if="e.sku_id" class="pull-left text-truncate overflow-hidden">
                  <a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${e.sku_id}`" class="badge badge-info" target="_blank">{{e.sku_id}} <i class="fa fa-external-link"></i></a>
                </div>
                <div class="pull-right">
                  <b-badge variant="light">{{e.tot_stock}} 개</b-badge>
                  <!--<template v-if="e.consumer !== e.price">
                    <b-badge variant="dark"><del>{{$utils.comma(e.consumer)}}</del> → {{$utils.comma(e.price)}} 원</b-badge>
                  </template>
                  <template v-else>
                    <b-badge variant="dark">{{$utils.comma(e.price)}} 원</b-badge>
                  </template>-->
                  <br/>
                </div>
              </div>
              <span v-if="picWidth < 200">
                <b-badge>{{e.options[0] && e.options[0].optnm || ''}}</b-badge>
                <b-badge v-for="opt of e.options.filter(e=>e.stock>0 && !e.not_found)" variant="light">{{opt.Size}}</b-badge>
              </span>
              <div v-else class="flex-wrap d-flex">
                <b-badge>{{e.options[0] && e.options[0].optnm || ''}}</b-badge>
                <div v-for="opt of e.options.filter(e=>e.stock>0 && !e.not_found)" class="flex-grow-0">
                  <b-badge variant="light">{{opt.Size}}</b-badge>
                  <b-badge variant="success" class="mr-1">{{opt.stock}}</b-badge>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="hasMore.list" class="text-center py-3">
          <b-button variant="primary" size="lg" @click="list(true)">더 가져오기</b-button>
        </div>
      </div>
    </template>

    <template v-if="tabIndex === 2">
      <b-card class="my-2">
        <pie-chart chart-id="pie" :chartdata="chart.data" :options="chart.options" style="position: relative; height: 450px"></pie-chart>
      </b-card>

      <b-card class="my-2">
        <div class="clearfix mb-1">
          <div class="pull-left col-form-label" v-if="total.stat">
            Total {{total.stat.value.comma()}} / Aggregations {{items.aggs.length}}
          </div>
          <b-btn class="pull-right" variant="success" @click="statXlsx()" :disabled="busy.statXlsx">
            Xlsx Down
            <b-spinner class="ml-1" small v-if="busy.statXlsx"></b-spinner>
          </b-btn>
        </div>

        <hot-table ref="hotTableStat" :settings="hotSettingsStat"></hot-table>
      </b-card>
    </template>
  </div>
</template>

<script>
import confirmedModal from '@/views/goods/ConfirmedModal.vue'
import xlsx from '@/views/modules/Xlsx.vue'
import {getHost} from '@/shared/api'
import {down} from '@/shared/impexp'
import * as momentBiz from 'moment-business-days';
import Vue from "vue";
import FormFields from "../modules/FormFields";
import FormOptions from "../modules/FormOptions";
import FormIncExc from "../modules/FormIncExc";
import FormPreset from "../FormPreset";
import ColorCheckbox from "../modules/ColorCheckbox";
import PieChart from '@/views/charts/Pie.vue'
import GoodsMixin from '@/views/goods/GoodsMixin'
import ListDataMixin from '../modules/ListDataMixin'
import {Types} from "mongoose";
import {formFieldsGen, formOptionsGen, formIncExcGen, groupFieldsGen} from "./Confirmed/formData";


const targetMap = { // 상품링크 매칭에 사용됨
  godo: '발란몰',
  lotteimall: '롯데아이몰',
  smartstore: '스토어팜(발란)',
};

const onedayDeliveryLimit = '14:00:59';
const onedayDeliveryLimitNext = '13:00:59';

export default {
  name: 'GoodsList',
  title: '등록상품조회',
  mixins: [
    GoodsMixin,
    ListDataMixin
  ],
  components: {FormFields, FormOptions, FormIncExc, FormPreset, ColorCheckbox, xlsx, confirmedModal, PieChart},
  data() {
    return {
      getHost,
      momentBiz, targetMap,
      shop: [],
      shopMap: {},
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      priceMetaMap: {},
      coupons: [],
      godoCols: [],
      selectedGodoCols: [],
      syncForce: false,
      defaultForm: {
        list: {
          search: '',
          shop: [],
          brand: [],
          category: [],
          shop_exclude: [],
          brand_exclude: [],
          category_exclude: [],

          real_shop: false,
          orderby: 'goods_no',

          goods_status: 'registered',
          manual: 'ALL',
          goodsType: 'ALL',
          stock_status: 'normal',
          display_status: 'view',

          delivery_type: 'ALL',
          oneday_delivery: 'ALL',
          today_pick: 'ALL',
          orderMade: 'ALL',
          deliveryExtra: 'ALL',
          store_pickup: 'ALL',
          gift_packing: 'ALL',
          goods_nm_en: 'ALL',
          hide_es: 'ALL',
          hasPrRule: 'ALL',
          image_check: 'ALL',

          usedGrade: this.$C.USED_GRADE.map(e => e.value),
          sku_id: '',
          tot_stock: '',
          opt_stock: '',
          min: '',
          max: '',
          limit: 100,
          skip: 0,

          created_from: '',
          created_to: '',
          updated_from: '',
          updated_to: '',
          goods_no_from: '',
          goods_no_to: '',

          options: false,
          optionSize: '',

          // optionStock: 1,
          major_color: this.$C.COLORS.map(e => e.name),

          goods_no_include: '',
          goods_no_exclude: '',
          goods_id_include: '',
          goods_id_exclude: '',
          gid_include: '',
          gid_exclude: '',
          sku_include: '',
          sku_exclude: '',

          minimal: false,
          godo: false,

          gm_id_exists: 'ALL',
          sortKey: '_object_id',
          sortDir: 'desc',
          fields: {},
          incExc: {},
        },
        es: {
          search: '',
          shop: [],
          brand: [],
          category: [],

          goods_status: 'registered',
          stock_status: 'ALL',
          display_status: 'ALL',
          gm_id_exists: 'ALL',
          manual: 'ALL',

          delivery_type: 'ALL',
          oneday_delivery: 'ALL',
          today_pick: 'ALL',
          orderMade: 'ALL',
          deliveryExtra: 'ALL',
          store_pickup: 'ALL',
          gift_packing: 'ALL',
          hide_es: 'ALL',

          sortKey: '_object_id',
          sortDir: 'desc',

          limit: 100,
          skip: 0,

          fields: {},
          incExc: {},

          major_color: this.$C.COLORS.map(e => e.name),
        },
        aggs: {
          // for stat
          groupFields: [],
          groupFieldSetting: {},
        }
      },
      form: {
        list: {},
        es: {},
        aggs: {}
      },
      tabIndex: 0,
      lastUri: '',
      query: '',
      esQuery: '',
      returnIdField: 'goods_no',
      collapse: {detail: false, esDetail: false},
      lastBody: {list: {}},
      items: {list: [], xlsx: []},
      total: {list: null}, // list: {value: 0, relation: 'eq'}
      busy: {list: false, listmore: false, syncStockMulti: false, terminate: false, stat: false, downIds: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController

      title: '',
      // item: {},
      itemAttr: {},
      imageIntent: 'default',
      fields: [
        'selected',
        {key: '_img60', label: '이미지', class: 'text-center'},
        {
          key: 'html2',
          label: `상품정보<br/><span class="badge badge-success">판매 가능수량</span><span class="badge badge-primary">SHOP 원본 수량</span>`
            + `<span class="badge badge-info">결제된 주문수량</span><span class="badge badge-warning">10분이내 주문 미결제수량</span>`,
          style: {minWidth: '250px'}
        },
        {
          key: 'html5',
          label: '<span class="badge badge-light">goods_id</span><br/><span class="badge badge-light">SKU ID</span><br/>'
            + '<span class="badge badge-light">통합 SKU</span>',
          class: 'text-center'
        },
        {
          key: 'html8',
          label: '<span class="badge alert-success">시즌</span><br/>' +
            '<span class="badge alert-light">컬러</span><br/>' +
            '<span class="badge alert-warning">원산지</span>',
          class: 'text-center'
        },
        // {
        //   key: 'html1',
        //   label: '<span class="badge badge-light">연동형태</span><br/><span class="badge alert-success">상품유형</span><br/>'
        //     + '<span class="badge badge-light">중고등급</span>',
        //   class: 'text-center'
        // },
        {
          key: 'html11',
          label: '<span class="badge badge-light">연동형태</span><br/><span class="badge badge-light">생성위치</span><br/><span class="badge badge-light">생성시각</span>',
          class: 'text-center',
          style: {minWidth: '105px'}
        },
        {key: 'html10', label: '배송정보', class: 'text-center', style: {minWidth: '105px'}},
        // {key: 'html4', label: '외부몰', class: 'text-center', style: {minWidth: '60px'}},
        {key: 'html3', label: '소비자가<br/>판매기준가<br/>', class: 'text-right w-100px'},
        {key: 'html9', label: '발란회원가<br/>EP송출가<br/><span class="grey-b3" title="로그인/비로그인 따라서 발란몰 노출 가격은 상이합니다.">(할인율<i class="fa fa-question-circle-o pointer"></i>)</span>', class: 'text-right w-100px'},
        {key: 'html6', label: '재고', style: {width: '50px'}, class: 'text-center'},
        {key: 'html7', label: '상태', class: 'text-center'},
        {key: '_actions', label: '상세', style: {width: '55px', textAlign: 'center'}, buttons: [{label: '상세', event: 'show_modal'}]},
      ],
      perPage: 20,
      itemMode: 'list',
      picFilter: '',
      picFilteredCnt: 0,
      picGroup: 'ALL',
      picWidth: 175,
      picInfoTop: true,
      picInfoBottom: true,
      modal: {
        detail: false,
        displayStatus: false,
        terminate: false,
        diffList: false,
        image: false,
        field: false,
        godo: false,
        query: false,
        esQuery: false,
        downIds: false
      },
      editMode: false,
      diff: null,
      priceHtml: '',
      displayStatus: 'view',
      displayStatusRelay: true,
      displayStatusReason: '',
      terminateReason: '',
      today: momentBiz().format('YYYY-MM-DD'),
      xlsx: {
        keys: [],
        labels: [],
      },
      options: {
        delivery_type: [
          {text: '전체', value: 'ALL'},
          {text: '해외', value: '해외'},
          {text: '국내', value: '국내'},
        ],
        image_check: [
          {text: '전체', value: 'ALL'},
          {text: '이미지없음', value: 'n'},
        ],
        hide_es: [
          {text: '전체', value: 'ALL'},
          {text: '포함', value: 'n'},
          {text: '제외', value: 'y'},
        ],
        AVAIL_Y: [
          {text: '전체', value: 'ALL'},
          {text: '가능', value: 'y'},
        ]
      },
      formOptions: formOptionsGen(this),
      formFields: formFieldsGen(),
      defaultFields: 'price:range,sku_id:eq,matched_sku_id:eq,b_rank:eq,season:eq,options.Size:like',
      customFormFields: [],
      formIncExc: formIncExcGen(),
      defaultIncExc: 'goods_no,goods_id,sku,matched_sku_id',
      customFormIncExc: [],
      deliveryExtraMap: this.$utils.arr2map(this.$C.DELIVERY_EXTRA, 'value', 'text'),

      // stat
      groupFields: groupFieldsGen(),
      chart: {
        data: {
          labels: ['Chart'],
          datasets: [{data: [1]}]
        },
        options: {
          // title: {display: true, text: 'Chart Title'},
          legend: {
            position: 'left'
          }
        }
      },
      hotSettingsStat: {
        colHeaders: [],
        columns: [],
        className: "htMiddle",
        autoWrapCol: true,
        autoWrapRow: false,
        columnSorting: true,
        manualColumnResize: true,
        width: '100%',
        height: 450,
        stretchH: "all",
        licenseKey: 'non-commercial-and-evaluation',
      },
      a: {
        'goods_status': 1,
        'gm_status': 1,
      }
    }
  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'collapse,itemMode,picWidth,perPage');
    Vue.set(this.form, 'list', this.$utils.clone(this.defaultForm.list));
    Vue.set(this.form, 'aggs', this.$utils.clone(this.defaultForm.aggs));
    for (const f of this.groupFields) {
      f.label = `${f.text}(${f.value})`;
    }

    this.busy.list = true;

    // const meta = await this.$api.getMeta('shop,brand,category,holiday,price,coupon');
    // if (!meta) return; // 미로그인 등으로 값이 없을 때
    //
    // // 공휴일 설정
    // let holidays = meta.holiday.map(e => {
    //   if (e.require) return momentBiz().format('YYYY-') + e.date;
    //   return e.date;
    // });
    // // 작년, 내년도 추가한다
    // holidays = holidays.concat(meta.holiday.filter(e => e.require).map(e => momentBiz().add(1, 'year').format('YYYY-') + e.date));
    // holidays = holidays.concat(meta.holiday.filter(e => e.require).map(e => momentBiz().subtract(1, 'year').format('YYYY-') + e.date));
    // momentBiz.updateLocale('kr', {
    //   holidays: holidays,
    //   holidayFormat: 'YYYY-MM-DD'
    // });
    // window.moment = momentBiz;
    //
    // meta.shop.forEach(s => {
    //   s.value = s.shop_id;
    //   s.label = `${s.use_yn !== 'y' ? '[미사용] ' : ''}${s.shop_id}. ${s.boutique}`;
    //   this.shopMap[s.shop_id] = s;
    // }); // use_yn 무관 일단 정보는 필요
    // // this.shop 에 use_yn = y 만 남는다면 shop preset 에서 use_yn = y 를 선택했을 때 갯수가 같아지는 문제가 있다.
    // this.shop = meta.shop.sort((a, b) => (a.use_yn === 'n' ? 10000 : 0) + a.shop_id - (b.use_yn === 'n' ? 10000 : 0) - b.shop_id);
    // // this.shop = meta.shop.filter(e => e.use_yn === 'y').sort((a, b) => a.shop_id - b.shop_id);
    //
    // this.brand = meta.brand.map(e => {
    //   return this.brandMap[e.brand_no] = {...e, value: e.brand_no, label: `${e.brand_nm} (${e.brand_nm_kr})`};
    // }).sort((a, b) => a.label.localeCompare(b.label));
    //
    // this.category = meta.category.map(e => {
    //   return this.categoryMap[e.category] = {...e, value: e.category, label: `${e.category} (${e.category_nm})`};
    // }).sort((a, b) => (a.value.length - b.value.length) * 10 + a.value.localeCompare(b.value));
    //
    // meta.price.forEach(e => {
    //   this.priceMetaMap[e.price_meta_name] = e.price_meta_value;
    // });
    //
    // this.coupons = meta.coupon

    // const meta = await this.$api.getMeta('shop,brand,category,holiday,price');
    // if (!meta) return; // 미로그인 등으로 값이 없을 때
    await this.$api.getAllMeta();
    this.shop = this.$S.m.shop.list.slice();
    this.shopMap = this.$S.m.shop.map;
    this.brand = this.$S.m.brand.list.slice();
    this.brandMap = this.$S.m.brand.map;
    this.category = this.$S.m.category.list.slice();
    this.categoryMap = this.$S.m.category.map;
    this.priceMetaMap = this.$S.m.price.map;
    this.coupons = this.$S.m.coupon.list.slice();

    // query string 으로 받은 내용을 폼에 적용한다.
    if (Object.keys(this.$route.query).length) {
      Object.keys(this.$route.query).forEach(k => {
        if (this.$route.query[k] != null) {
          const v = this.$route.query[k];
          if ('tot_stock,opt_stock,min,max,goods_no_from,goods_no_to,limit,skip'.split(',').includes(k)) {
            this.form.list[k] = +v;
          } else if ('real_shop,minimal,godo'.split(',').includes(k)) {
            this.form.list[k] = !(!v || v === 'false');
          } else if (k === '$shop') {
            this.form.list.shop = v.split(',').map(e => this.shopMap[e]);
          } else if (k === '$shop_exclude') {
            this.form.list.shop_exclude = v.split(',').map(e => this.shopMap[e]);
          } else if (k === '$category') {
            this.form.list.category = v.split(',').map(e => this.categoryMap[e]);
          } else if (k === '$category_exclude') {
            this.form.list.category_exclude = v.split(',').map(e => this.categoryMap[e]);
          } else if (k === '$brand') {
            this.form.list.brand = v.split(',').map(e => this.brandMap[e]);
          } else if (k === '$brand_exclude') {
            this.form.list.brand_exclude = v.split(',').map(e => this.brandMap[e]);
          } else if (k.startsWith('collapse.')) {
            const key = k.replace('collapse.', '');
            this.collapse[key] = !(!v || v === 'false');
          } else if (k === 'usedGrade' || k === 'major_color') {
            this.form.list[k] = v.split(',');
          } else if (k === 'tabIndex') {
            this.tabIndex = +v;
          } else if (Object.keys(this.form.list).includes(k)) {
            this.form.list[k] = v;
          }
        }
      });
    }

    // 발란몰로 보낼 수 있는 컬럼 정리
    Object.keys(this.$C.GODO_COL_TARGET).forEach(k => {
      let col = this.$C.CONFIRMED_COLUMNS[k];
      if (col) {
        this.godoCols.push({value: k, text: col.name, desc: col.desc});
      }
    });
    Object.keys(this.$C.CF_TO_BL_GOODS_ADDON).filter(k => k !== 'goods_no').forEach(k => {
      let col = this.$C.CONFIRMED_COLUMNS[k];
      if (col) {
        this.godoCols.push({value: k, text: col.name, desc: col.desc});
      }
    });

    if (this.$route.query.runCustomQuery) {
      const queryStr = localStorage.getItem('params#confirmed.customQuery');
      // console.log('queryStr', queryStr);
      if (queryStr) {
        this.tabIndex = 1;
        const esQuery = JSON.parse(queryStr);
        esQuery.size = 100;
        this.esQuery = JSON.stringify(esQuery);
        localStorage.removeItem('params#confirmed.customQuery');
        this.runCustomQuery();
        return;
      }
    }

    const no = this.$route.params.no;
    if (no) {
      this.form.list.search = no;
      this.form.list.display_status = 'ALL';
      await this.list();
      let item = this.items.list.filter(e => e.goods_no === +no)[0];
      if (item) this.showModal({item});
      return;
    }
    this.list();
  },
  async beforeDestroy() {
    Object.values(this.ac).filter(e => e).forEach(e => e.abort());
    this.$utils.setStatus(this.$options.name, this, 'collapse,itemMode,picWidth,perPage');
  },
  sockets: {
    confirmed_query(q) {
      console.log('confirmed_query:', q);
    }
  },
  watch: {
    picFilter(v) {
      if (v) {
        this.items.list.forEach(e=>{
          let uv = v.toUpperCase();
          e.filtered = ~e.goods_nm.toUpperCase().indexOf(uv)
            || ~e.sku_id.toUpperCase().indexOf(uv)
            || ~e.goods_id.toUpperCase().indexOf(uv)
            || ~(e.goods_no+'').toUpperCase().indexOf(uv)
            || ~(e.org_name || '').toUpperCase().indexOf(uv)
            || ~(e.brand_nm || '').toUpperCase().indexOf(uv)
            || ~(e.brand_nm_kr || '').toUpperCase().indexOf(uv)
            || ~(e.category_nm || '').toUpperCase().indexOf(uv)
        });
        this.picFilteredCnt = this.items.list.filter(e=>e.filtered && (this.picGroup === 'ALL' || e.selected)).length;
      }
    },
    picGroup(v) {
      this.picFilteredCnt = this.items.list.filter(e=>e.filtered && (v === 'ALL' || e.selected)).length;
    },
    itemMode() {
      this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    },
    picWidth() {
      this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    },
    perPage() {
      this.$utils.setStatus(this.$options.name, this, 'itemMode,picWidth,perPage');
    }
  },
  methods: {
    godoPriceLambda(price) {
      return price;
    },
    godoMemberPriceLambda(godoPrice, shop_id) {
      return shop_id === 204 ? godoPrice : Math.floor(godoPrice * (100 - this.priceMetaMap.global_coupon_rate) / 1000) * 10;
    },
    ssPriceLambda(e) {
      return e;
    },
    calcIncExc(inc, exc) {
      let include = inc ? inc.trim().split(/\r?\n/g).map(e => e.trim()) : [];
      let exclude = exc ? exc.trim().split(/\r?\n/g).map(e => e.trim()) : [];
      if (include.length && exclude.length) { // 둘 다 존재시 exclude 를 include 에서 제외
        const excludeMap = this.$utils.arr2map(exclude);
        include = include.filter(e => !excludeMap[e]);
        exclude = [];
      }
      return [include, exclude];
    },
    makeListFormBody() {
      const form = this.form.list;

      const shop = form.shop.map(e => e.shop_id);
      const brand = form.brand.map(e => e.value);
      const category = form.category.map(e => e.value);
      const shop_exclude = form.shop_exclude.map(e => e.shop_id);
      const brand_exclude = form.brand_exclude.map(e => e.value);
      const category_exclude = form.category_exclude.map(e => e.value);
      const major_color = form.major_color.length === this.$C.COLORS.length ? [] : form.major_color; // 전체 선택일 경우 비우기
      const usedGrade = form.usedGrade.length === this.$C.USED_GRADE.length ? [] : form.usedGrade; // 전체 선택일 경우 비우기

      const fields = this.$refs.fields && this.$refs.fields.makeFieldsQuery() || [];
      const incExc = this.$refs.incExc && this.$refs.incExc.makeIncExcQuery() || [];

      let [goods_no_include, goods_no_exclude] = this.calcIncExc(form.goods_no_include, form.goods_no_exclude);
      goods_no_include = goods_no_include.map(e => e.split(/\D+/g)).flat().map(e => +e); // 123,134 로 한 줄에 들어오는 숫자 특별처리
      goods_no_exclude = goods_no_exclude.map(e => e.split(/\D+/g)).flat().map(e => +e); // 123,134 로 한 줄에 들어오는 숫자 특별처리
      const [goods_id_include, goods_id_exclude] = this.calcIncExc(form.goods_id_include, form.goods_id_exclude);
      const [gid_include, gid_exclude] = this.calcIncExc(form.gid_include, form.gid_exclude);
      const [sku_include, sku_exclude] = this.calcIncExc(form.sku_include, form.sku_exclude);

      const body = {
        ...form,
        fields,
        incExc,
        shop,
        brand,
        category,
        shop_exclude,
        brand_exclude,
        category_exclude,
        major_color,
        usedGrade,
        goods_no_include,
        goods_no_exclude,
        goods_id_include,
        goods_id_exclude,
        gid_include,
        gid_exclude,
        sku_include,
        sku_exclude,
        coupons: this.coupons
      };
      if (this.query.trim()) {
        try {
          eval('body._query = {' + this.query + '}');
        } catch (e) {
          //
        }
      }
      return body;
    },
    async list(more) {
      const form = this.form.list;
      if (form.limit > 10000 && !form.minimal) return alert('10000 개를 초과하여 검색할때는 필수정보만 가져오기를 선택해주세요');
      const body = this.makeListFormBody();
      this.lastUri = more === true ? this.lastUri : (this.tabIndex === 0 ? '/goods/confirmed' : '/goods/confirmed/es');
      const j = await this.$api.postTable(this, this.lastUri, body, {more, fnAssign: this.assignTableData});
      // 검색결과중 검색필드와 일치하는 데이터가 있다면 url 을 바꿔준다.
      if (j && j.list.filter(e => e.goods_no + '' === form.search).length && location.hash === '#/goods') {
        history.replaceState(null, null, location.origin + '/#/goods/' + form.search);
      }
    },
    async getEsQuery() {
      const body = this.makeListFormBody();
      body.returnQuery = true;
      const j = await this.$api.postJson('/goods/confirmed/es', body);
      if (j) {
        this.esQuery = JSON.stringify(j.query, null, 2);
        this.modal.esQuery = true;
      }
    },
    async runCustomQuery() {
      try {
        const customQuery = JSON.parse(this.esQuery);
        const form = this.form.list;
        if ((form.limit > 10000 || customQuery.size > 10000) && !form.minimal) return alert('10000 개를 초과하여 검색할때는 필수정보만 가져오기를 선택해주세요');
        const body = this.makeListFormBody();
        this.lastUri = '/goods/confirmed/es';
        this.modal.esQuery = false;
        await this.$api.postTable(this, this.lastUri, {...body, customQuery}, {more: false, fnAssign: this.assignTableData});
      } catch (e) {
        alert('JSON 형식에 맞게 입력해주세요');
      }
    },
    async downIds() {
      const body = this.makeListFormBody();
      body.returnIdField = this.returnIdField;
      this.busy.downIds = true;
      const j = await this.$api.postJson('/goods/confirmed/es', body);
      if (j) {
        down(j.data.map(e => ({id: e})), [body.returnIdField], ['id'], `ConfirmedIds_${this.$utils.dt()}`, 'txt');
        //
        // this.$refs.json_data.value = JSON.stringify({
        //   data: j.data.map(e => ({id: e})),
        //   keys: ['id'],
        //   labels: [body.returnIdField],
        //   type: 'txt',
        //   name: `ConfirmedIds_${this.$utils.dt()}.txt`
        // });
        // this.$refs.xlsx_form.submit();
      }
      this.busy.downIds = false;
    },
    assignTableData(e) {
      if (!e.goods_id) {
        e.html2 = `<a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success">${e.shop_id}</a>` +
          this.makeMallLinkBadge(e.goods_no, this.MALL_URL, {goodsType: e.goodsType}) + '<br/>';
        return;
      } // minimal 인 경우
      const styleEllipsis = 'white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: inline-block;';
      if (e.category) {
        let cates = [];
        for (let i = 3; i <= e.category.length; i += 3) {
          if (e.category.substring(i - 3, i) === '001') cates.push('전체');
          else cates.push(this.categoryMap[e.category.substring(0, i)] && this.categoryMap[e.category.substring(0, i)].category_nm || '?');
        }
        e.cate = cates.join(' > ');
      } else if (e.brand_no !== 0) {
        // 개인결제창이 아닌 경우
        console.log('카테고리 없음 : ', e);
      }
      if (!e.consumer) e.consumer = e.price;

      // 배송유형 및 일자, 구매대행 판단
      let shop = this.shopMap[e.shop_id];
      if (shop) {
        if (shop.delivery_type !== 'both') {
          e.delivery_type = {abroad: '해외', domestic: '국내'}[shop.delivery_type];
          e.delivery_day = shop.delivery_day;
        } else {
          if (e.delivery_type === '국내') {
            e.delivery_day = shop.delivery_day;
          } else {
            e.delivery_day = shop.delivery_day_abroad || 21;
          }
        }
        e.delivery_str = e.delivery_type + (shop.logistics === 'direct' ? (e.delivery_type === '해외' && shop.pccc_feed === 'y' ? '구매대행' : '직배송') : '');
      } else {
        e.delivery_str = 'SHOP배송정보없음';
        e.delivery_day = 21; // 이전부터 있던 하드코딩, 6일인 이유는?
      }
      // e.godoPrice = this.godoPriceLambda(e.price, {category: e.category});
      e.godoPrice = e.gg_price;
      e.godoMemberPrice = this.godoMemberPriceLambda(e.godoPrice, e.shop_id);
      e.godoMemberPriceRate = Math.round(100 - e.godoMemberPrice / e.price * 100); // hub 가격 대비 발란몰 가격의 추가할인율
      e.cfPriceEpPriceDiscountRate = Math.round(100 - e.epPrice / e.price * 100); // hub 가격 대비 발란몰 가격의 추가할인율
      e.ssPrice = this.ssPriceLambda(e.price);

      e.img = e.images && e.images[0] ? `https://i.balaan.io/${e.image_path}${e.images[0].thumb_200}` : e.img_urls[0];
      // e.html1 = `<a href="${this.MALL_URL}/shop/goods/goods_view.php?goodsno=${e.goods_no}" target="_blank">${e.goods_no}</a><br/>`
      //   + `<span class="badge badge-light">${e.category}</span>`;
      e._capp = this.cat2capp(e._cat);

      e.html2 = this.makeShopBadge(shop) + ' ' + this.makeBrandBadge(e.brand_no, e.brand_nm, {type: this.brandMap[e.brand_no].brand_type}) + ' ' +
        this.makeCategoryBadge(e.cate, e.category) + ' ' + this.makeMallLinkBadge(e.goods_no, this.MALL_URL, {copyIcon: true, goodsType: e.goodsType}) + '<br/>' + e.goods_nm +
        `<br/>` + this.makeOptionsBadge(e.options, {lines: 2});
      e.html3 = `<span className="grey-b3">${this.$utils.comma(e.consumer)}</span>` + '<br/>' +
        `${this.$utils.comma(e.standardPrice)}`;
      e.html6 = e.tot_stock;
      // e.html4 = e.links.map(s=>`<span class="badge badge-primary">${s.mall}</span>&nbsp;`
      //   + (targetMap[s.mall] ? `<a href="${this.$C.MALL_GOODS_LINK[targetMap[s.mall]]}${s.mall_id}" target="_blank" class="badge badge-info" title="${s._cdt}">${s.mall_id} <i class="fa fa-external-link"></i></a>` : `<span class="badge badge-secondary">${s.mall_id}</span>`)
      // ).join('<br/>');
      // e.html9 = e.godoPrice != null ?
      //   `${this.$utils.rnc(e.godoPrice)}<br/>${this.$utils.rnc(e.godoMemberPrice)}<br/><span class="grey-b3">(${e.godoMemberPriceRate} %)</span>` :
      //   `-`;
      e.html9 = `${this.$utils.rnc(e.price)}<br/>${this.$utils.rnc(e.epPrice)}<br/><span class="grey-b3">(${e.cfPriceEpPriceDiscountRate} %)</span>`
      e.html5 = (e.crawl_url && e.crawl_url.startsWith('http') ? `<a href="${`/r?u=${encodeURIComponent(e.crawl_url)}`}" target="_blank" class="badge badge-info">${e.goods_id} <i class="fa fa-external-link"></i></a>` : `<span class="badge badge-light">${e.goods_id}</span>`) + '<br/>'
        + (e.sku_id ? `<a href="https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${encodeURIComponent(e.sku_id)}"` +
          ` target="_blank" class="badge badge-success">` +
          `<span style="${styleEllipsis} max-width: 80px;">${e.sku_id}</span>` +
          ` <i class="fa fa-external-link"></i></a>` : '') + `<br/>`
        + (e.matched_sku_id ? `<a href="https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${encodeURIComponent(e.matched_sku_id)}"` +
          ` target="_blank" class="badge badge-success">` +
          `<span style="${styleEllipsis} max-width: 80px;">${e.matched_sku_id}</span>` +
          ` <i class="fa fa-external-link"></i></a>` : '') + `<br/>`
      ;
      e.html1 = (e.manual ? `<span class="badge alert-danger">파트너관리</span>` : '') + '<br/>'
        + (e.goodsType === 'used' ? `<span class="badge alert-warning">빈티지</span><br/><span class="badge badge-light">${e.usedGrade}</span>` :
          '<span class="badge alert-success">새상품</span>')
      ;
      e.html7 = this.makeGoodsStatusBadges(e) + (this.form.list.godo ? '<br/>' + this.$utils.badge(e.open) : '');
      e.html8 = (e.season ? `<span class="badge alert-success" title="${(e.season || '').escapeHtml()}" style="${styleEllipsis} max-width: 80px;">` +
        `${e.season}</span>` : '') + '<br/>' +
        (e.major_color ? `<span class="badge alert-light" title="${(e.major_color || '').escapeHtml()}" style="${styleEllipsis} max-width: 80px;">` +
          `${e.major_color}</span>` : '') + '<br/>' +
        (e.origin ? `<span class="badge alert-warning" title="${(e.origin || '').escapeHtml()}" style="${styleEllipsis} max-width: 80px;">${e.origin}</span>` : '');

      const odLimit = this.$moment().format('YYYY-MM-DD HH:mm:ss') <= '2022-03-31 ' + onedayDeliveryLimit ? onedayDeliveryLimit : onedayDeliveryLimitNext;
      e._badgeOnedayDelivery = e.oneday_delivery === 'Y' ?
        `<span class="badge alert-danger">오늘도착</span><br/><small>마감시간: ${odLimit.slice(0, 5)}</small><br/>` :
        '';
      e._badgeTodayPick = e.today_pick ?
        `<span class="badge alert-success">오늘출발</span><br/><small>마감시간: ${e.today_pick_time}</small><br/>` :
        '';
      e._badgeOrderMade = e.orderMade ? '<span class="mr-1 badge alert-secondary">주문제작</span>' : '';
      e._badgeDeliveryExtra = e.deliveryExtra && e.deliveryExtra.length ? e.deliveryExtra.map(extra => `<span class="mr-1 badge alert-secondary">${e.delivery_type} ${this.deliveryExtraMap[extra]}</span>`).join('<br/>') : '';
      e._badgeGift = e.gift_packing ? '<span class="mr-1 badge badge-danger">포장</span>' : '';
      e._badgeStore = e.store_pickup ? '<span class="badge badge-primary">픽업</span>' : '';

      e.html10 = `<span class="badge alert-${e.delivery_type === '해외' ? 'success' : 'info'}">${e.delivery_str}</span>` +
        (!e.orderMade && (!e.deliveryExtra || !e.deliveryExtra.includes('long')) ? `<span class="badge badge-light">${e.delivery_day}일</span><br/>` : '') +
        e._badgeOnedayDelivery + e._badgeTodayPick + e._badgeOrderMade + e._badgeDeliveryExtra + e._badgeGift + e._badgeStore + (e._badgeGift || e._badgeStore ? '<br/>' : '');
      e.html11 = (e.manual ? `<span class="badge alert-danger">파트너관리</span>` : '<span class="badge alert-info">자동(FEED)</span>') + '<br/>' +
        this.badgeCat(e._cat) + `<br/><span class="badge alert-light" title="${e._cdt}">${this.reduceDT(e._cdt)}</span>`;

      // 브랜드속성을 추가해준다.
      // https://www.notion.so/4198820b7eea40a09c6728a155e7d423?pvs=4#4cb38f7ed4114026a5aa8605e1ee034f
      e.brand_type_kr = (this.$C.BRAND_TYPE_MAP[this.brandMap[e.brand_no].brand_type] || {}).name || '';
    },
    toggleUsedGrade(grade) {
      if (!grade) {
        this.form.list.usedGrade = this.form.list.usedGrade.length === this.$C.USED_GRADE.length ? [] : this.$C.USED_GRADE.map(e => e.value);
      } else {
        this.form.list.usedGrade = this.$C.USED_GRADE.filter(e => e.value[0] === grade).map(e => e.value);
      }
    },

    showModal(row) {
      this.$refs.confirmedModal.showModal(row);
    },
    exclude(item) {
      this.form.list.goods_no_exclude += ' ' + item.goods_no;
    },
    async setDisplayStatusModal() {
      let selectedItems = this.items.list.filter(e => e.selected);
      if (!selectedItems.length) return alert('상태를 변경할 상품을 선택해주세요');
      this.displayStatusReason = '';
      this.modal.displayStatus = true;
    },
    async setDisplayStatus() {
      if (!this.displayStatusReason) return alert('변경사유를 입력해주세요');
      if (this.items.list.filter(e => e.selected).some(e => e.manual) && !confirm('파트너 직접관리상품이 포함되어 있습니다. 노출상태를 변경하시겠습니까?')) return;
      let j = await this.$api.postJson('/goods/setDisplayStatus', {
        form: {
          goods_no: this.items.list.filter(e => e.selected).map(e => e.goods_no),
          display_status: this.displayStatus, relay: this.displayStatusRelay, reason: this.displayStatusReason
        }
      });
      if (j) {
        alert('작업이 백그라운드에서 진행됩니다.');
      }
    },
    async imageProcessingModal() {
      let selected = this.items.list.filter(e => e.selected);
      if (!selected.length) return alert('이미지를 재처리할 상품을 선택해주세요');
      this.modal.image = true;
    },
    async imageProcessing(goods_no) {
      // if (!goods_no && this.items.list.filter(e=>e.selected).some(e=>e.manual) && !confirm('파트너 직접관리상품이 포함되어 있습니다. 진행하시겠습니까?')) return;
      let j = await this.$api.postJson('/goods/requestImageProcessing', {
        goods_nos: goods_no ? [goods_no] : this.items.list.filter(e => e.selected).map(e => e.goods_no),
        intent: this.imageIntent,
        ignoreManual: true
      });
      if (j) {
        alert(`${j.cnt} 개 상품에 대해 이미지 작업이 시작되었습니다.` + (j.errors.length ? `\n${j.errors.length} 개의 에러가 발생했습니다:\n${j.errors.map(e => e.error).join('\n')}` : ''));
        this.modal.image = false;
      }
    },
    async syncGodoModal() {
      let selected = this.items.list.filter(e => e.selected);
      if (!selected.length) return alert('발란몰로 싱크할 상품을 선택해주세요');
      this.modal.godo = true;
      this.syncForce = false;
    },
    async syncGodo(goods_no) {
      const goods_nos = goods_no ? [goods_no] : this.items.list.filter(e => e.selected).map(e => e.goods_no);
      const j = await this.$api.postJson('/goods/syncGodo', {goods_nos, cols: this.selectedGodoCols, force: this.syncForce});
      if (j) {
        this.$utils.alert(`${goods_nos.length} 개 상품이 싱크되었습니다.`);
        this.modal.godo = false;
      }
    },
    async syncStockMulti() {
      let selected = this.items.list.filter(e => e.selected);
      if (!selected.length) return alert('재고를 싱크할 상품을 선택해주세요');
      this.busy.syncStockMulti = true;
      let j = await this.$api.postJson(`/goods/syncStockMulti`, {goods_nos: selected.map(e => e.goods_no), renew: true});
      this.busy.syncStockMulti = false;
      if (j) {
        this.$modal.show({title: '갱신요청 성공', html: '작업이 시작되었습니다. 백그라운드에서 진행됩니다.'});
      }
    },
    // async removeSoldoutOption() {
    //   let selected = this.items.list.filter(e => e.selected);
    //   if (!selected.length) return alert('품절옵션을 원복할 상품을 선택해주세요');
    //   this.busy.removeSoldoutOption = true;
    //   let j = await this.$api.postJson(`/goods/removeSoldoutOption`, {goods_nos: selected.map(e => e.goods_no)});
    //   this.busy.removeSoldoutOption = false;
    //   if (j) {
    //     this.$modal.show({title: '성공', html: '품절옵션 원복이 완료되었습니다'});
    //   }
    // },
    async terminateModal() {
      const selectedItems = this.items.list.filter(e => e.selected);
      if (!selectedItems.length) return alert('종료처리할 상품을 선택해주세요');
      this.terminateReason = '';
      this.modal.terminate = true;
    },
    async terminate(complete) {
      if (!this.terminateReason) return alert('상품 종료사유를 입력해주세요');
      const selected = this.items.list.filter(e => e.selected);
      if (!selected.length) return alert('종료처리할 상품을 선택해주세요');
      if (complete) {
        if (!confirm(`완전종료처리를 한 상품은 주문, 장바구니, 위시리스트에 상품이 있어도 제거합니다. 이미지 저작권 등 특수한 케이스일 때만 사용해주세요. ` +
          `${selected.length} 개 상품을 완전종료처리 하시겠습니까?`)) return;
      } else {
        if (!confirm(`종료처리를 한 상품은 파트너가 여전히 상품을 보내주고 있어도 신규상품 목록에서 제외됩니다. ${selected.length} 개 상품을 종료처리 하시겠습니까?`)) return;
      }

      this.busy.terminate = true;
      let j = await this.$api.postJson(`/goods/terminate`, {goods_nos: selected.map(e => e.goods_no), complete, reason: this.terminateReason});
      this.busy.terminate = false;

      if (j) {
        if (j.ok === -1) {
          // 파트너관리 상품이 있는 경우
          if (!confirm(j.msg)) return;
          this.busy.terminate = true;
          j = await this.$api.postJson(`/goods/terminate`, {goods_nos: selected.map(e => e.goods_no), force: true, complete, reason: this.terminateReason});
          this.busy.terminate = false;
          if (!j) return;
        }
        if (complete) {
          this.$modal.show({title: '성공', html: `${j.cnt} 개 상품이 완전종료처리 되었습니다`});
        } else {
          this.$modal.show({title: '성공', html: `${j.cnt} 개 상품이 종료처리 되었습니다`});
        }
        this.modal.terminate = false;
        this.list();
      }
    },
    btnAction(row, event) {
      if (event === 'show_modal') {
        this.showModal(row);
      }
    },
    selectAll(selected) {
      this.items.list.filter(e => (this.picFilter ? e.filtered : true) && (this.picGroup === 'ALL' || e.selected)).forEach(e => e.selected = selected);
      this.recalcPicFilteredCnt();
      this.$forceUpdate();
    },
    picClick(e) {
      e.selected = !e.selected;
      console.log(e);
      this.$forceUpdate();
    },
    recalcPicFilteredCnt() {
      this.picFilteredCnt = this.items.list.filter(e => e.filtered && (this.picGroup === 'ALL' || e.selected)).length;
    },
    jsonModal(title, json) {
      this.$modal.show({title, type: 'json', item: json});
    },
    async preDown(type, {template = ''} = {}) {
      // && !this.$S.user.group.includes('operator')
      if (!template && !this.$R(['BALAANEER', 'E_GOODS_R'])) return this.downPartner(type);
      let selectedItems = this.items.list.filter(e => e.selected);
      if (!selectedItems.length) return alert('다운받을 상품을 선택해주세요');
      let baseFields = {}, dupFields = {}, lastFields = {};
      let priceType = 'consumer,sale,supply';
      let name = `Confirmed_${this.$utils.dt()}.${type}`;

      // price_table 이 없는 상품들에 대해서 받아와야 한다.
      const noPtItems = selectedItems.filter(e => !e.price_table);
      const noPtGoodsNos = noPtItems.map(e => e.goods_no);
      if (noPtGoodsNos.length) {
        const j = await this.$api.postJson('/goods/priceTableMap', {goodsNos: noPtGoodsNos});
        if (j) {
          noPtItems.forEach(e => e.price_table = j.ptMap[e.goods_no]);
        }
      }

      if (template === 'tf') {
        priceType = 'supply';
        name = `ConfirmedTF_${this.$utils.dt()}.${type}`;
        let titles = '발란코드,상품명,샵번호,샵이름,카테고리,카테고리명,goods_id,SKU,통합 SKU ID,브랜드번호,브랜드명,한글브랜드명,상품상태,노출상태,옵션,소비자가,발란회원가,재고합계,오늘도착 여부,오늘출발 여부,오늘출발 마감시각,매장픽업 여부,상품관리코드,상품별 반품비,상품별 교환비,생성시각,등록시각,송출시각,수정시각,마스터 ID,최초 마스터 매칭 시각,supply_세전KRW,supply_해외배송비KRW,supply_세후가격,최종판매가,최종판매가RoundUp'.split(',');
        Object.entries(this.$C.CONFIRMED_COLUMNS).forEach(([k, v]) => {
          if (v.use !== false && ~titles.indexOf(v.name)) baseFields[k] = v.name;
        });
        dupFields = {
          befTaxPriceWithoutDelivery: '세전KRW',
          localDeliveryCost: '해외배송비KRW',
          vatPrice: '세후가격',
        };
        lastFields = {
          finalPrice: '최종판매가',
          finalPriceRoundUp: '최종판매가RoundUp',
        };
      } else {
        Object.entries(this.$C.CONFIRMED_COLUMNS).forEach(([k, v]) => {
          if (v.use !== false) baseFields[k] = v.name;
          if (v.name === '원본가') baseFields['price_table.0.priceLimitRule.priceLimit'] = '가격고정가';
          if (v.name === '발란회원가') baseFields['epPrice'] = 'EP송출가'
        });
        baseFields = {
          ...baseFields,
          'options.0.Size': '사이즈:0',
          'options.0.goods_consumer': '소비자가:0',
          'options.0.goods_price': '발란회원가:0',
          'options.0.stock': '재고:0',
          'img_urls.0': '이미지',
        };
        dupFields = {
          basePrice: '기본현지가',
          localPrice: '세전현지가',
          befTaxPriceWithoutDelivery: '세전KRW',
          localDeliveryCost: '해외배송비KRW',
          befTaxPrice: '배송비포함KRW',
          cTax: '관세',
          idvTax: '개소세',
          eduTax: '교육세',
          idvEduTax: '개소세교육세합',
          vatKrw: '부가세',
          vatPrice: '세후가격',
          rebatePrice: '판매수수료포함가격',
          pgFee: 'PG수수료',
          aftPgPrice: 'PG수수료포함가격',
          domestic_delivery_cost: '국내배송비',
          finalPrice: '국내가격',
          finalPriceRoundUp: '국내가격올림',
          finalPriceWithoutVatPg: 'VAT&PG제외가격',
        };
        lastFields = {
          customs_rate: '관세율',
          vat: '부가세율',
          idvEduRate: '개소세교육세율',
          pgRate: 'PG수수료',
          rebate: '판매수수료KRW',
          befMargin: '수정전마진',
          margin: '수정마진',
          marginRate: '수정마진율',
          discountRate: '할인율',
          finalPrice: '최종판매가',
          finalPriceRoundUp: '최종판매가RoundUp',
          finalMargin: '최종마진',
          finalMarginRate: '최종마진율',
        };
      }
      // console.log(selectedItems)
      let fields = [Object.keys(baseFields).join(',')].concat(priceType.split(',').map(e => Object.keys(dupFields).map(k => `price_table.0.${e}.${k}`).join(','))).concat(Object.keys(lastFields).map(k => `price_table.0.${k}`).join(',')).join(',');
      let header = [Object.values(baseFields).join(',')].concat(priceType.split(',').map(e => Object.values(dupFields).map(k => `${e}_${k}`).join(','))).concat(Object.values(lastFields).join(',')).join(',');

      this.$refs.json_data.value = JSON.stringify({data: selectedItems, keys: fields.split(','), labels: header.split(','), type, name});
      this.$refs.xlsx_form.submit();
    },
    downPartner(type) {
      // 다양한 데이터를 다양한 구성으로 다양한 타입으로 다운받는다.
      let selected = this.items.list.filter(e => e.selected);
      if (!selected.length) return alert('다운받을 상품을 선택해주세요');

      let keys = ('goods_no,goods_id,goods_nm,category,category_nm,brand_nm,brand_nm_kr,brand_no,sku_id,origin,season,color,description,composition,' +
        'measurements,modelsize,optnm,md_name,shop,shop_id,category_code,consumer,price,discount_rate,tot_stock,crawl_url,stock_status,goods_status,' +
        'display_status,created_dt,confirmed_dt,registered_dt,updated_dt').split(',')
        , values = keys.map(e => this.$C.CONFIRMED_COLUMNS[e]);
      let name = values.map(e => e.name);
      let code = keys, codeMap = {};
      keys.forEach(e => codeMap[e] = e);

      let optnm_idx = code.indexOf('optnm'); // 옵션명
      name.splice(optnm_idx + 1, 0, '옵션');
      code.splice(optnm_idx + 1, 0, 'options');
      selected.forEach(e => {
        e.options.map(o => `${o.Size}||${o.goods_consumer}||${o.goods_price}||${o.stock}`).join('^^');
      });
      let rows = [codeMap, ...selected];
      down(rows, name, code, `${'Confirmed'}_${this.$utils.dt()}`, type);
    },
    async downAD(type) {
      let selectedItems = this.items.list.filter(e => e.selected);
      if (!selectedItems.length) return alert('다운받을 상품을 선택해주세요');

      const header = ['발란코드', '상품명', '성별', '카테고리', '카테고리명', '마스터 ID', '통합 SKU ID', '브랜드명', '한글브랜드명', '브랜드속성', '익스프레스',
        '발란회원가', '최대 혜택가'];
      const fields = ['goods_no', 'goods_nm', 'gender_category', 'category', 'category_nm', 'gm_id', 'matched_sku_id', 'brand_nm', 'brand_nm_kr',
        'brand_type_kr', 'today_pick', 'price', 'epPrice'];
      const firstCode = {
        '009': 'WOMEN',
        '010': 'MEN',
        '011': '골프',
        '020': '키즈',
        '030': '홈/리빙',
        '040': '스포츠/웰니스',
        '050': '테크/디지털',
        '060': '반려동물',
        '070': '아트'
      };
      selectedItems.forEach(e => {
        e.today_pick = e.today_pick ? 'Y' : 'N';
        e.gender_category = e.category ? firstCode[e.category.substring(0, 3)] : '';
      });

      this.$refs.json_data.value = JSON.stringify({
        data: selectedItems,
        keys: fields,
        labels: header,
        type,
        name: `ShoppingSearchAD_${this.$utils.dt()}.${type}`
      });
      this.$refs.xlsx_form.submit();
    },
    // async downOptions(type) { // 2023-09-27 disable
    //   let selectedItems = this.items.list.filter(e => e.selected);
    //   if (!selectedItems.length) return alert('다운받을 상품을 선택해주세요');
    //
    //   const entries = Object.entries(this.$C.CONFIRMED_OPTIONS_COLUMNS);
    //   const fields = entries.map(([k, v]) => k);
    //   const header = entries.map(([k, v]) => v.name);
    //
    //   const codeMap = {};
    //   fields.forEach(e => codeMap[e] = e);
    //   const rows = [codeMap];
    //   selectedItems.forEach(e => {
    //     e.options.forEach(opt => {
    //       rows.push({
    //         ...e,
    //         optName: opt.optnm,
    //         optSize: opt.Size,
    //         optConsumer: opt.goods_consumer,
    //         optPrice: opt.goods_price,
    //         optSupply: opt.goods_supply,
    //         optStock: opt.stock
    //       });
    //     });
    //   });
    //
    //   this.$refs.json_data.value = JSON.stringify({data: rows, keys: fields, labels: header, type, name: `ConfirmedOptions_${this.$utils.dt()}.${type}`});
    //   this.$refs.xlsx_form.submit();
    // },
    copy(col, {separator} = {}) {
      const selected = this.items.list.filter(e => e.selected);
      if (!selected.length) return alert('복사할 상품을 선택해주세요');

      let res;
      if (separator === 'tab') {
        res = this.$utils.copyToClipboard(selected.map(e => e[col]).join('\t'));
      } else if (separator === 'comma') {
        res = this.$utils.copyToClipboard(selected.map(e => e[col]).join(','));
      } else if (separator === 'quotes') {
        res = this.$utils.copyToClipboard(selected.map(e => `'${e[col].toString().replace(/'/g, "\\'")}'`).join(',\n'));
      } else {
        res = this.$utils.copyToClipboard(selected.map(e => e[col]).join('\n'));
      }

      if (res) this.$alertTop(`복사되었습니다`);
    },

    async stat() {
      const form = this.form.list;
      const aggs = this.form.aggs;
      if (aggs.groupFields.length === 0) return alert('1 개 이상의 Group By 필드를 선택해 주세요');

      const fieldMap = this.$utils.arr2map(this.groupFields, 'value');
      const selectedFields = aggs.groupFields.map(e => fieldMap[e]);

      if (selectedFields.some(e => e.nested)) {
        const firstNestedIndex = selectedFields.indexOf(selectedFields.find(e => e.nested));
        if (selectedFields.slice(firstNestedIndex + 1).some(e => !e.nested)) {
          return alert('nested field(option.Size 등 depth 가 있는 내부 필드) 는 일반 field 뒤에 존재해야 합니다');
        }
      }

      const shop = form.shop.map(e => e.shop_id); // 전체 선택일 경우 비우기
      const brand = form.brand.map(e => e.value);
      const category = form.category.map(e => e.value);
      const shop_exclude = form.shop_exclude.map(e => e.shop_id);
      const brand_exclude = form.brand_exclude.map(e => e.value);
      const category_exclude = form.category_exclude.map(e => e.value);
      const major_color = form.major_color.length === this.$C.COLORS.length ? [] : form.major_color; // 전체 선택일 경우 비우기

      const fields = this.$refs.fields && this.$refs.fields.makeFieldsQuery() || [];
      const incExc = this.$refs.incExc && this.$refs.incExc.makeIncExcQuery() || [];

      const body = {
        form: {
          ...form,
          fields,
          incExc,
          shop,
          brand,
          category,
          shop_exclude,
          brand_exclude,
          category_exclude,
          major_color,
        },
        aggs
      };
      this.busy.stat = true;
      const j = await this.$api.postJson('/goods/confirmed/aggs', body);
      this.busy.stat = false;
      if (j) {
        this.items.aggs = j.rows;
        const top19 = j.rows.slice(0, 19);
        this.chart.data = {
          labels: top19.map(e => aggs.groupFields.map(f => e[f] == null ? `(null)` : `${e[f]}`).join(' / ')),
          datasets: [{data: top19.map(e => e.count)}]
        };
        if (j.rows.length > 19) {
          const etcCount = j.rows.slice(19).sum('count');
          this.chart.data.labels.push('(기타)');
          this.chart.data.datasets[0].data.push(etcCount);
        }
        this.total.stat = j.total;

        const hs = this.$utils.clone(this.hotSettingsStat);
        hs.colHeaders = aggs.groupFields.map(f => {
          const {avgOn, avg, minOn, min, maxOn, max, sumOn, sum} = aggs.groupFieldSetting[f];
          return [f, avgOn && `${f} avg(${avg})`, minOn && `${f} min(${min})`, maxOn && `${f} max(${max})`, sumOn && `${f} sum(${sum})`, f + ' count'].filter(e => e);
        }).flat().slice(0, -1).concat(['count']);
        hs.columns = hs.colHeaders.map(e => ({data: e, readOnly: true, renderer: 'nullish'}));
        // hs.columns = hs.colHeaders.map(e => ({data: e, readOnly: true}));
        this.$refs.hotTableStat.hotInstance.updateSettings(hs);
        this.$refs.hotTableStat.hotInstance.loadData(j.rows);

        this.$forceUpdate();
      }
    },
    groupFieldsChange() {
      const settingKeyMap = this.$utils.arr2map(Object.keys(this.form.aggs.groupFieldSetting));
      this.form.aggs.groupFields.forEach(f => {
        if (!settingKeyMap[f]) {
          const orgField = this.groupFields.find(e => e.value === f);
          Vue.set(this.form.aggs.groupFieldSetting, f, {size: 100, type: orgField.type, nested: orgField.nested, avgOn: false, minOn: false, maxOn: false, sumOn: false});
        }
        delete settingKeyMap[f];
      });
      Object.keys(settingKeyMap).forEach(k => {
        delete this.form.aggs.groupFieldSetting[k];
      });
    },
    statXlsx() {
      const keys = this.form.aggs.groupFields.map(f => [f, f + ' count']).flat().slice(0, -1).concat(['count']);
      down(this.items.aggs, keys, keys, `ConfirmedStat_${this.$utils.dt()}`, 'xlsx');
    },
    resetForm() {
      const fields = this.form.list.fields;
      const incExc = this.form.list.incExc;
      this.form.list = this.$utils.clone(this.defaultForm.list);
      Vue.set(this.form.list, 'fields', fields);
      Vue.set(this.form.list, 'incExc', incExc);

      this.$refs.fields.resetFieldValues();
      this.$refs.incExc.resetValues();
    },
    resetStatForm() {
      this.resetForm();
      this.form.aggs = this.$utils.clone(this.defaultForm.aggs);
    },
  }
}
</script>

<style>
.cf_img img {
  border: 1px solid #eee;
  margin-left: 3px;
}

.cf_desc {word-break: break-word;}
.cf_desc img {
  width: 100%;
  max-width: 300px;
  display: block;
}
.cf_desc .mh-600 {
  max-height: 600px;
  overflow-y: hidden;
}

.mp_img img {
  border: 1px solid #eee;
  margin-left: 3px;
}

.mp_desc {word-break: break-word;}
.mp_desc img {
  width: 100%;
  max-width: 300px;
  display: block;
}
.mp_desc .mh-600 {
  max-height: 600px;
  overflow-y: hidden;
}

</style>
